/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
import React from 'react'
import { ErrorBoundary } from '@sevenrooms/core/ui-kit/core/ErrorBoundary'
import { accessibleComponentWrapper, AccessibleDivComponent } from 'svr/common/A11y'
import GoogleSignIn from 'svr/widget/common/GoogleSignIn'
import FacebookIconSvg from '../../../../../images/facebook.svg'
import * as styles from '../assets/styles/login'

const SpanComponent = props => <span {...props} />
const AccessibleSpanComponent = accessibleComponentWrapper(SpanComponent, false)

const Login = ({ loginUserFB, loginUserGP, skipLogin, guestCheckout }) => {
  document.getElementsByTagName('body')[0].scrollTop = 0
  const guestCheckoutSection = guestCheckout ? (
    <div style={styles.loginSeperator}>
      <div style={{ marginBottom: '11px' }}>
        <div style={styles.lineBreak} />
        <span style={styles.lineBreakText}>OR</span>
        <div style={styles.lineBreak} />
      </div>
      <div style={styles.guestButton}>
        <AccessibleSpanComponent tabIndex="0" role="button" onClick={skipLogin} style={styles.guestText}>
          Continue as guest
        </AccessibleSpanComponent>
      </div>
    </div>
  ) : null
  return (
    <div style={styles.viewWrapper}>
      <div style={styles.leadingText}>
        Please select an option
        <br />
        below to continue checkout:
      </div>
      <AccessibleDivComponent aria-label="Login With Facebook" onClick={loginUserFB} style={styles.fbButton}>
        <div style={styles.fbBox}>
          <img src={FacebookIconSvg} alt="Facebook Icon" style={styles.fbIcon} />
        </div>
        <span style={styles.fbText}>Login with Facebook</span>
      </AccessibleDivComponent>
      <AccessibleDivComponent aria-label="Login With Google">
        {/* eslint-disable-next-line no-console */}
        <ErrorBoundary fallback={<div />} onError={error => console.error(error)}>
          <GoogleSignIn handleCallback={loginUserGP} style={styles.gpButton} />
        </ErrorBoundary>
      </AccessibleDivComponent>
      {guestCheckoutSection}
    </div>
  )
}

export default Login
