import {
  TRY_GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_LONGTERM_EVENTS_START,
  GET_LONGTERM_EVENTS_SUCCESS,
  GET_LONGTERM_EVENTS_FAILURE
} from '../actions/ActionTypes'

export const isFetchingEvents = (state = false, action) => {
  switch (action.type) {
    case TRY_GET_EVENTS:
      return true
    case GET_EVENTS_SUCCESS:
      return false
    case GET_EVENTS_FAILURE:
      return false
    default:
      return state
  }
}

export const isFetchingLongtermEvents = (state = false, action) => {
  switch (action.type) {
    case GET_LONGTERM_EVENTS_START:
      return true
    case GET_LONGTERM_EVENTS_SUCCESS:
    case GET_LONGTERM_EVENTS_FAILURE:
      return false
    default:
      return state
  }
}
