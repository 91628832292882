import React from 'react'
import { calendarBackground } from '../assets/styles/calendar'
import CalendarBody from './CalendarBody'
import CalendarNav from './CalendarNav'

const Calendar = props => {
  const { availability, venueToday, calendarInfo, stepForwardMonth, stepBackMonth, initScrollAnimation, hoverDate, selectDate } = props
  return (
    <div style={calendarBackground}>
      <CalendarNav venueToday={venueToday} calendarInfo={calendarInfo} stepForwardMonth={stepForwardMonth} stepBackMonth={stepBackMonth} />
      <CalendarBody
        venueToday={venueToday}
        availability={availability}
        calendarInfo={calendarInfo}
        initScrollAnimation={initScrollAnimation}
        hoverDate={hoverDate}
        selectDate={selectDate}
      />
    </div>
  )
}

export default Calendar
