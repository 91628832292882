/* global widgetInit */
import { colorLuminance } from '../../utils/color'

// eslint-disable-next-line camelcase
const { color_primary } = widgetInit.settings
const lighterPrimary = colorLuminance(color_primary, 0.2)

export const header = {
  position: 'fixed',
  width: '100%',
  minWidth: '300px',
  maxWidth: '700px',
  zIndex: 100,
  marginTop: -2,
  marginLeft: -2,
  height: '64px',
  boxShadow: '0 2px 3px rgba(0,0,0,.2)',
  backgroundColor: lighterPrimary,
}

export const wizard = {
  width: '100%',
  height: '64px',
}

export const tab = {
  fill: color_primary,
  outline: 'none',
}

export const currentTab = {
  fill: lighterPrimary,
  outline: 'none',
}

export const iconContainer = {
  position: 'absolute',
  top: 0,
  width: '33.33333%',
  height: '100%',
  textAlign: 'center',
  pointerEvents: 'none',
}

export const icon = {
  color: 'white',
  marginTop: '22px',
  fontSize: '18px',
}
