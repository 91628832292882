/* eslint-disable camelcase, consistent-return, no-param-reassign */
/* global widgetInit, FREEDOM_PAY_PUBLIC_KEY, textCommonPaymentErrorUnexpectedError */
import flex from '@cybersource/flex-sdk-web'
import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import moment from 'moment-timezone'
import 'whatwg-fetch'
import { stripeCard } from 'svr/common/stripeCard'
import { setDataFor3Dsecure } from 'svr/component-lib/Widget/Payments/actions'
import { SET_STRIPE_INTENT_CLIENT_SECRET } from 'svr/component-lib/Widget/Payments/ActionTypes'
import { AccountTypes, TransactionTypes } from 'svr/lib/Payments/Constants'
import { CardCodes, CyberEncrypt } from 'svr/lib/Payments/CyberSource'
import { RSAEncrypt } from 'svr/lib/Payments/FreedomPay'
import { FREEDOMPAY_SOFT_DECLINE_CODES } from 'svr/lib/Payments/FreedomPayGateway'
import { AESEncrypt, PipeFormat } from 'svr/lib/Payments/Network'
import { IntentSources } from 'svr/lib/Payments/StripeGateway'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'
import { prepTags } from 'widget/dining/utils/convertData'
import { parseBoolean } from 'widget/events/utils/preloadedState'
import { dismissModal } from 'widget/paylink/actions/navigation'
import * as PaymentResultStatus from 'widget/universal/components/payments/response'
import { isUnifiedPaymentResponse } from 'widget/universal/components/payments/util'
import { getConfigProvider } from '@sevenrooms/connect-config'
import { convertToFormData } from '@sevenrooms/core/api'
import Analytics from '../services/AnalyticsServices'
import { calcCharges, getTaxRate } from '../utils/calcCharges'
import camelCaseObject from '../utils/camelCaseObject'
import { getLocaleDateFormat } from '../utils/date'
import {
  GET_EVENTS_SUCCESS,
  INVALIDATE_FIELD,
  POST_CHECKOUT_FAILURE,
  POST_CHECKOUT_RELOAD_ON_FAILURE,
  POST_CHECKOUT_SUCCESS,
  SET_FREEDOMPAY_INITIAL_DATA,
  SET_FREEDOMPAY_IFRAME_INITIALIZED,
  SET_FREEDOMPAY_VISIBLE,
  TOGGLE_CHECKOUT_ERROR_MODAL,
  TRY_GET_EVENTS,
  TRY_POST_CHECKOUT,
  DISMISS_SPINNER,
  SET_FEATURE_FLAGS,
  GET_LONGTERM_EVENTS_START,
  GET_LONGTERM_EVENTS_SUCCESS,
  GET_LONGTERM_EVENTS_FAILURE,
} from './ActionTypes'
import {
  cleanCybersourcePaymentInformation,
  payerAuthenticationCheckEnrollmentComplete,
  payerAuthenticationSetupComplete,
} from './cybersourceThreeDs'
import { selectEvent } from './navigation'

export const setFreedompayInitialData = data => ({
  type: SET_FREEDOMPAY_INITIAL_DATA,
  data,
})

export const setFreedompayIframeInitialized = data => ({
  type: SET_FREEDOMPAY_IFRAME_INITIALIZED,
  data,
})

export const setFreedompayVisible = data => ({
  type: SET_FREEDOMPAY_VISIBLE,
  data,
})

export const tryGetEvents = () => ({ type: TRY_GET_EVENTS })

export const dismissSpinner = () => ({ type: DISMISS_SPINNER })

export const getEventsSuccess = (data, venueInfo, isMultiInventoryTypeEnabled) => ({
  type: GET_EVENTS_SUCCESS,
  data,
  venueInfo,
  isMultiInventoryTypeEnabled,
})

export const getLongtermEventsStart = () => ({ type: GET_LONGTERM_EVENTS_START })

export const getLongtermEventsSuccess = () => ({ type: GET_LONGTERM_EVENTS_SUCCESS })

export const getLongtermEventsFailure = () => ({ type: GET_LONGTERM_EVENTS_FAILURE })

const setPaymentPendingResponse = paymentPendingResponse => ({
  type: ActionTypes.SET_PAYMENT_PENDING_RESPONSE,
  paymentPendingResponse,
})

// On first widget load, only do first 62 days in two requests of 31 days, for max speed
const INIT_BATCH_MAX_DAYS_OUT = 62
const INIT_BATCH_NUM_DAYS = 31

// Once first 62 days load, load remainder of year in 10 requests of 30 days, and a remainder request of 4 days
const SECOND_BATCH_MAX_DAYS_OUT = 366 // Can book out max 1 year
const SECOND_BATCH_NUM_DAYS = 30

export const fetchEvents = () => (dispatch, getState) => {
  dispatch(tryGetEvents())
  const state = getState()
  const { experienceId } = state.queryObj
  const { isMultiInventoryTypeEnabled } = state.featureFlags

  if (state.queryObj.date) {
    const queryDateMoment = moment(state.queryObj.date)
    const daysDiff = queryDateMoment.diff(moment(), 'days')
    const batchSize = 8
    if (daysDiff >= 0) {
      const fromDaysOut = daysDiff > 0 ? daysDiff - 1 : 0
      const toDaysOut = fromDaysOut + batchSize
      return fetchEventsBatches(getState, fromDaysOut, toDaysOut, batchSize, null, experienceId).then(secondResponseJson => {
        const data = loadEventsResults(dispatch, getState, secondResponseJson)
        dispatch(getEventsSuccess(data, state.venueInfo, isMultiInventoryTypeEnabled))
      })
    }
  }
  fetchEventsBatches(getState, 0, INIT_BATCH_MAX_DAYS_OUT, INIT_BATCH_NUM_DAYS, null, experienceId).then(responseJson => {
    if (!_.isEmpty(responseJson.data.event_dates)) {
      // Load these results before fetching remainder of year
      const data = loadEventsResults(dispatch, getState, responseJson)
      dispatch(getEventsSuccess(data, state.venueInfo, isMultiInventoryTypeEnabled))
    }
    dispatch(getLongtermEventsStart())
    fetchEventsBatches(getState, INIT_BATCH_MAX_DAYS_OUT, SECOND_BATCH_MAX_DAYS_OUT, SECOND_BATCH_NUM_DAYS, responseJson, experienceId)
      .then(secondResponseJson => {
        const data = loadEventsResults(dispatch, getState, secondResponseJson)
        dispatch(getEventsSuccess(data, state.venueInfo, isMultiInventoryTypeEnabled))
        dispatch(getLongtermEventsSuccess())
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('Error fetching longterm events:', error)
        dispatch(getLongtermEventsFailure())
      })
  })
}

const fetchEventsBatches = (getState, fromDaysOut, toDaysOut, batchSize, previouslyLoadedEvents = null, experienceId = null) => {
  const state = getState()
  const event_id = state.queryObj.eventId
  const getUrl = `${state.widgetSettings.baseUrl}/api-yoa/events/widget?venue=${state.venueInfo.venueUrlKey}${
    experienceId ? `&experience_id=${experienceId}` : ''
  }${event_id ? `&event_id=${event_id}` : ''}`

  return Promise.all(
    _.range(fromDaysOut, toDaysOut, batchSize).map(from_days => {
      const batchDaysToAdd = from_days + batchSize - 1 // Inclusive
      const to_days = batchDaysToAdd <= toDaysOut ? batchDaysToAdd : toDaysOut
      return fetch(`${getUrl}&from_days=${from_days}&to_days=${to_days}`)
    })
  )
    .then(responses => Promise.all(responses.map(response => response.json())))
    .then(responseJsons => {
      // Combine results into one responseJson
      if (previouslyLoadedEvents !== null) {
        responseJsons.push(previouslyLoadedEvents)
      }
      return _.reduce(
        responseJsons,
        (singleResponseJson, responseJson) => {
          _.forEach(responseJson.data.events_lookup, (events_lookup, event_id) => {
            singleResponseJson.data.events_lookup[event_id] = events_lookup
          })
          _.forEach(responseJson.data.event_dates, (event_dates, event_id) => {
            singleResponseJson.data.event_dates[event_id] = singleResponseJson.data.event_dates[event_id] || []
            Array.prototype.push.apply(singleResponseJson.data.event_dates[event_id], event_dates)
          })
          _.forEach(responseJson.data.events_availabilities, (event_availability, event_id) => {
            singleResponseJson.data.events_availabilities[event_id] = singleResponseJson.data.events_availabilities[event_id] || {}
            Object.assign(singleResponseJson.data.events_availabilities[event_id], event_availability)
          })
          _.forEach(responseJson.data.unopened_events, (unopened_event, event_id) => {
            singleResponseJson.data.unopened_events[event_id] = singleResponseJson.data.unopened_events[event_id] || {}
            Object.assign(singleResponseJson.data.unopened_events[event_id], unopened_event)
          })
          _.forEach(responseJson.data.tiered_events, (tiered_event, event_id) => {
            singleResponseJson.data.tiered_events[event_id] = singleResponseJson.data.tiered_events[event_id] || {}
            Object.assign(singleResponseJson.data.tiered_events[event_id], tiered_event)
          })
          _.forEach(responseJson.data.floorplan_images_lookup, (floorplan_images_lookup, event_id) => {
            singleResponseJson.data.floorplan_images_lookup[event_id] = singleResponseJson.data.floorplan_images_lookup[event_id] || {}
            Object.assign(singleResponseJson.data.floorplan_images_lookup[event_id], floorplan_images_lookup || {})
          })
          return singleResponseJson
        },
        {
          data: {
            events_lookup: {},
            event_dates: {},
            events_availabilities: {},
            unopened_events: {},
            tiered_events: {},
            floorplan_images_lookup: {},
          },
        }
      )
    })
}

const loadEventsResults = (dispatch, getState, responseJson) => {
  const state = getState()
  const events = _.reduce(
    responseJson.data.events_lookup,
    (eventsMap, item) => _.assign({}, eventsMap, { [item.id]: camelCaseObject(item) }),
    {}
  )
  const responseAvail = responseJson.data.events_availabilities
  const data = _.reduce(
    responseJson.data.event_dates,
    (reduceMap, dateList, eventId) => {
      const resultMap = _.assign({}, reduceMap)

      if (!state.queryObj.date && state.queryObj.eventId && state.queryObj.eventId !== eventId) {
        return resultMap
      }
      _.forEach(dateList, date => {
        if (!state.queryObj.eventId && state.queryObj.date && state.queryObj.date !== date) {
          return
        }
        if (!resultMap.availability[date]) {
          resultMap.availability[date] = {}
        }
        if (!resultMap.availability[date][eventId]) {
          resultMap.availability[date][eventId] = {}
        }
        if (responseAvail[eventId]) {
          _.forEach(responseAvail[eventId][date], inventoryItem => {
            const inventoryInstance = {
              availabilityId: inventoryItem.id,
              isHighlighted: false,
              remainingQuantity: inventoryItem.remaining_quantity,
              totalQuantity: inventoryItem.total_quantity,
            }
            resultMap.availability[date][eventId][inventoryItem.inventory_id] = inventoryInstance
            const camelItem = camelCaseObject(_.omit(inventoryItem, ['remaining_quantity', 'total_quantity']))
            resultMap.inventory[inventoryItem.inventory_id] = camelItem
          })
        }
      })
      return resultMap
    },
    { availability: {}, inventory: {} }
  )
  data.events = !state.queryObj.date && state.queryObj.eventId ? _.pick(events, state.queryObj.eventId) : events
  data.unopenedEvents = responseJson.data.unopened_events
  data.tieredEvents = responseJson.data.tiered_events
  data.floorplanImagesLookup = responseJson.data.floorplan_images_lookup
  const eventsArr = Object.keys(data.events)
  if (eventsArr.length === 1 && state.queryObj.experienceId) {
    const event = data.events[eventsArr[0]]

    if (event.startDate === event.endDate) {
      dispatch(selectEvent(event.startDate, event.id))
    }
  }

  return data
}

export const invalidateField = (field, error) => ({
  type: INVALIDATE_FIELD,
  field,
  error,
})

export const tryPostCheckout = () => ({ type: TRY_POST_CHECKOUT })

export const postCheckoutSuccess = () => (dispatch, getState) => {
  const state = getState()
  const charges = calcChargesFromState(state)
  Analytics.successfulCheckout(charges.charge_amount, state.venueInfo.currencyCode)
  dispatch({ type: POST_CHECKOUT_SUCCESS })
}

export const postCheckoutFailure = response => dispatch => {
  Analytics.failedCheckout(response)
  dispatch({
    type: POST_CHECKOUT_FAILURE,
    response,
  })
}

export const toggleCheckoutErrorModal = response => ({
  type: TOGGLE_CHECKOUT_ERROR_MODAL,
  response,
})

export const postCheckoutPageReloadOnFailure = (errorMessage, actionCallback) => ({
  type: POST_CHECKOUT_RELOAD_ON_FAILURE,
  errorMessage,
  actionCallback,
})

export const setIntentClientSecret = stripeIntentClientSecret => ({
  type: SET_STRIPE_INTENT_CLIENT_SECRET,
  stripeIntentClientSecret,
})

const convertToFormatData = obj => {
  const prunedData = _.pickBy(obj, Boolean)
  return _.reduce(
    prunedData,
    (formData, val, key) => {
      formData.append(key, val)
      return formData
    },
    new FormData()
  )
}

const fetchUserData = state => ({
  fname: state.formFields.get('firstName'),
  lname: state.formFields.get('lastName'),
  email: state.formFields.get('email'),
  loginSite: state.user.get('site'),
  location: state.user.get('location'),
  birthday: state.user.get('birthday') || state.formFields.get('birthday'),
  salutation: state.user.get('salutation') || state.formFields.get('salutation'),
  userId: state.user.get('userId'),
  picture: state.user.get('picture'),
  phone: state.formFields.get('phoneNumber'),
  phone_locale: state.formFields.get('phoneNumberLocale'),
  message: state.formFields.get('message'),
  recaptcha: state.formFields.get('recaptcha'),
})

const calcChargesFromState = state => {
  const {
    selectedInventory,
    price,
    quantity,
    serviceChargePercentage,
    taxPercentage,
    gratuityPercentage,
    additionalFee,
    promoCodeEntity,
    additionalFeeTaxPercentage,
  } = getEventCharges(state)
  return calcCharges(
    selectedInventory,
    quantity,
    price,
    serviceChargePercentage,
    taxPercentage,
    gratuityPercentage,
    additionalFee,
    promoCodeEntity,
    additionalFeeTaxPercentage
  )
}

const getEventCharges = state => {
  const { inventoryItems } = state.userSelection.toJS()
  const { inventoryId, availabilityId } = inventoryItems[0]
  const selectedInventoryItem = state.inventoryCart.cart.find(item => item.inventoryId === inventoryId)
  const selectedInventory = state.entities.inventory.get(inventoryId)
  const price = selectedInventory.get('price')
  const applyServiceCharge = selectedInventory.get('applyServiceCharge')
  const serviceChargeType = selectedInventory.get('serviceChargeType')
  const serviceChargePercentage = applyServiceCharge
    ? {
        DEFAULT_SERVICE_CHARGE: state.venueInfo.venueDefaultServiceCharge,
        SPECIFIC_SERVICE_CHARGE: selectedInventory.get('serviceChargeAmount'),
      }[serviceChargeType]
    : 0
  const chargeGratuity = selectedInventory.get('chargeGratuity')
  const gratuityAmountType = selectedInventory.get('gratuityAmountType')
  const gratuityPercentage = chargeGratuity
    ? {
        DEFAULT_FIXED: state.venueInfo.venueDefaultGratuity,
        CUSTOM_FIXED: selectedInventory.get('gratuityAmount'),
        CUSTOMER_VARIABLE: state.formFields.get('customTip') || 0,
      }[gratuityAmountType]
    : 0
  const chargeTax = selectedInventory.get('chargeTax')
  const { taxGroups } = state.venueInfo
  const taxGroupId = selectedInventory.get('taxGroupId')
  const taxPercentage = chargeTax ? getTaxRate(taxGroups, taxGroupId) : 0
  const additionalFee = selectedInventory.get('additionalFee')
  const promoCodeEntity = state.entities.promoCode
  const chargeAdditionalFeeTax = selectedInventory.get('chargeAdditionalFeeTax')
  const additionalFeeTaxId = selectedInventory.get('additionalFeeTaxId')
  const additionalFeeTaxPercentage = chargeAdditionalFeeTax ? getTaxRate(taxGroups, additionalFeeTaxId) : 0

  return {
    availabilityId,
    selectedInventory,
    price,
    quantity: selectedInventoryItem.quantity,
    serviceChargePercentage,
    taxPercentage,
    gratuityPercentage,
    additionalFee,
    promoCodeEntity,
    additionalFeeTaxPercentage,
  }
}

const clientIdPostData = () =>
  _.reduce(
    ['tracking_slug'],
    (accum, prop) => {
      accum[prop] = widgetInit[_.camelCase(prop)]
      return accum
    },
    {}
  )

const prepPostData = (state, cardToken, cardData = null, isStripePaymentElement = false) => {
  const { availabilityId, selectedInventory, quantity, serviceChargePercentage, taxPercentage, gratuityPercentage } = getEventCharges(state)

  const {
    charge_amount,
    charge_base_amount,
    charge_service_charge_amount,
    charge_gratuity_amount,
    charge_tax_amount,
    charge_additional_amount,
    charge_additional_fee_tax_amount,
  } = calcChargesFromState(state)

  const encryptor = new CyberEncrypt()
  const formFields = state.formFields.toJS()
  cardData = cardData
    ? JSON.stringify(cardData)
    : encryptor.encrypt([formFields.cardNum, formFields.cardMonthExp, formFields.cardYearExp, formFields.cardCvv].join('|'))

  const { client_tags, reservation_tags } = prepTags(state.entities.tags.toJS())
  const tracking = clientIdPostData().tracking_slug
  const trackingSlug = tracking && tracking !== 'None' ? tracking : new URLSearchParams(window.location.search).get('tracking') || ''
  const formData = {
    ...fetchUserData(state),
    card_token: cardToken,
    client_token: state.clientInfo.id,
    card_data: cardData,
    party_size: quantity * selectedInventory.get('entryPerReservation'),
    availability_id: availabilityId,
    venue_id: state.venueInfo.id,
    client_tags,
    reservation_tags,
    quantity,
    charge_amount,
    charge_base_amount,
    charge_service_charge_amount,
    charge_service_charge: serviceChargePercentage || '',
    charge_gratuity: gratuityPercentage || '',
    charge_gratuity_amount,
    charge_additional_amount,
    charge_additional_fee_tax_amount,
    charge_tax: taxPercentage || '',
    charge_tax_amount,
    encryption_id: state.widgetSettings.encryptionId || '',
    tracking_slug: trackingSlug,
    experience_id: state.queryObj.experienceId || '',
    display_reservation_sms_opt_in: state.formFields.get('displayReservationSmsOptInButton'),
    promo_code_id: state.entities.promoCode ? state.entities.promoCode.id : null,
    zip: state.formFields.get('cardZipCode'),
    card_first_name: state.formFields.get('cardFirstName'),
    card_last_name: state.formFields.get('cardLastName'),
    email: state.formFields.get('email'),
    is_stripe_payment_element: isStripePaymentElement,
    browser_screen_width: Number.isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth,
    browser_screen_height: Number.isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
  }
  if (state.venueInfo.isCybersourceBillingAddressRequired) {
    _.extend(formData, {
      address1: state.formFields.get('address1'),
      city: state.formFields.get('city'),
      locality: state.formFields.get('locality'),
      administrativeArea: state.formFields.get('administrativeArea'),
      countryCode: state.formFields.get('countryCode'),
      postalCode: state.formFields.get('postalCode'),
    })
  }
  if (formData.birthday) {
    if (getLocaleDateFormat(state.venueInfo.municipality.locale) === 'dd/mm') {
      // save the date in default US format for proper display formatting elsewhere
      formData.birthday = `${formData.birthday.split('/')[1]}/${formData.birthday.split('/')[0]}`
    }
  }
  if (state.formFields.get('displayVenueGroupMarketingOptInButton')) {
    _.extend(formData, {
      venue_group_marketing_opt_in: state.formFields.get('agreedToVenueGroupMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayTailoredCommunicationOptInButton')) {
    _.extend(formData, {
      tailored_communication_opt_in: state.formFields.get('agreedToTailoredCommunicationOptIn'),
    })
  }
  if (state.formFields.get('displayVenueSpecificMarketingOptInButton')) {
    _.extend(formData, {
      venue_specific_marketing_opt_in: state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayVenueSmsMarketingOptInButton')) {
    _.extend(formData, {
      venue_sms_marketing_opt_in: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayReservationSmsOptInButton')) {
    _.extend(formData, {
      reservation_sms_opt_in: state.formFields.get('agreedToReservationSmsOptIn'),
    })
  }
  if (state.formFields.get('displayAboveAgeConsentPolicy')) {
    _.extend(formData, {
      agreed_to_above_age_consent: state.formFields.get('agreedToAboveAgeConsentOn'),
    })
  }
  if (state.featureFlags.isMultiInventoryTypeEnabled) {
    formData.bundle = JSON.stringify(
      state.userSelection.toJS().inventoryItems.map(i => ({
        quantity: i.quantity,
        availability_id: i.availabilityId,
      }))
    )
  }

  return formData
}

function handleErrors(response) {
  return response.json().catch(() => undefined)
}

const postCheckoutFetch = (formData, postUrl) => (dispatch, getState) => {
  dispatch(tryPostCheckout())

  const { venueInfo } = getState()

  if (formData.card_token && venueInfo.threedSecure) {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = `/booking/${venueInfo.id}/3d_secure_routing/`

    for (const key in formData) {
      if (formData[key] === undefined || formData[key] === null) {
        continue
      }
      const input = document.createElement('input')
      input.name = key
      input.value = formData[key]
      form.appendChild(input)
    }

    const router = document.createElement('input')
    router.name = 'router'
    router.value = 'events'
    form.appendChild(router)

    document.body.appendChild(form)
    return form.submit()
  }

  return fetch(postUrl, {
    body: convertToFormatData(formData),
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => {
      const state = getState()
      if (response.status === 200) {
        response.json().then(respBody => {
          if (isUnifiedPaymentResponse(state.venueInfo.paymentType)) {
            if (respBody.status === PaymentResultStatus.RESULT_PENDING) {
              if (respBody?.redirect_url) {
                // extract to gatewayBase and override if needed
                window.location = respBody.redirect_url
              } else {
                dispatch(setPaymentPendingResponse(respBody))
              }
            } else if (respBody.status === PaymentResultStatus.RESULT_SUCCESS) {
              dispatch(postCheckoutSuccess())
            }
          } else if (venueInfo.paymentType === AccountTypes.ADYEN && respBody.adyen_action_for_3d_secure) {
            dispatch(setDataFor3Dsecure(respBody.adyen_action_for_3d_secure))
          } else if (venueInfo.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX && respBody.enrollment_result) {
            dispatch(payerAuthenticationCheckEnrollmentComplete(respBody))
          } else {
            dispatch(postCheckoutSuccess())
          }
        })
      } else {
        // The following block of code reloads the Shift4 iframe, which is an unfortunately necessary hacky way to ensure that
        // the credit card fields can accept input after payment via Shift4 fails.
        if (venueInfo.paymentType === AccountTypes.SHIFT_4) {
          const shift4iframe = document.getElementById('i4goFrame')?.querySelector('iframe')
          if (shift4iframe) {
            shift4iframe.src += ''
          }
        }
        dispatch(cleanState(venueInfo.paymentType))
        handleErrors(response).then(respBody => {
          dispatch(postCheckoutFailure(respBody))
          if (formData.is_stripe_payment_element) {
            dispatch(createStripeIntent())
          }
        })
      }
    })
    .catch(response => {
      dispatch(cleanState(venueInfo.paymentType))
      handleErrors(response).then(respBody => {
        dispatch(postCheckoutFailure(respBody))
        if (formData.is_stripe_payment_element) {
          dispatch(createStripeIntent())
        }
      })
    })
}

const updateAndSubmitPaymentElement = (stripe, elements, result, stripeIntentClientSecret, calced, postUrl) => (dispatch, getState) => {
  const state = getState()

  const intentForm = new FormData()
  intentForm.append('amount', calced.charge_amount)
  intentForm.append('token', result?.paymentIntent?.id)

  fetch(`/booking/widget/${state.venueInfo.venueUrlKey}/update_payment_intent`, {
    body: intentForm,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    // eslint-disable-next-line consistent-return
    .then(response => {
      if (response.errors) {
        let messageStringFinal = ''
        response.errors.forEach(errorItem => {
          const messageString = errorItem.split(':')
          messageStringFinal += `* ${messageString[1]}`
        })
        dispatch(postCheckoutFailure(String(messageStringFinal)))
      } else {
        elements.fetchUpdates().then(() => {
          dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret, postUrl))
        })
      }
    })
}

export const stripePaymentMethodSubmitHandler = stripe => (dispatch, getState) => {
  const state = getState()
  const { stripeIntentClientSecret, elements } = state.commonPayment
  const postUrl = `${state.widgetSettings.baseUrl}/booking/widget/${state.venueInfo.venueUrlKey}/event_book`

  const calced = calcChargesFromState(state)

  stripe.retrievePaymentIntent(stripeIntentClientSecret).then(result => {
    if (result?.paymentIntent?.amount !== calced.charge_amount * 100) {
      dispatch(updateAndSubmitPaymentElement(stripe, elements, result, stripeIntentClientSecret, calced, postUrl))
    } else {
      dispatch(submitPaymentElement(stripe, elements, stripeIntentClientSecret, postUrl))
    }
  })
}

const stripePaymentElementErrorEventHandler = (state, error) => dispatch => {
  const { paymentTypeMessage } = state.commonPayment
  const paymentTypeErrorMessage = `Something went wrong with ${paymentTypeMessage}. Please choose a different payment method and try again.`
  if (error?.type === 'validation_error') {
    dispatch(dismissSpinner())
  } else if (error?.type === 'invalid_request_error' && error?.message.includes(paymentTypeMessage)) {
    dispatch(postCheckoutFailure({ errors: String(paymentTypeErrorMessage) }))
  }
}

const submitPaymentElement = (stripe, elements, stripeIntentClientSecret, postUrl) => (dispatch, getState) => {
  const state = getState()
  elements.submit().then(ev => {
    if (ev?.error) {
      dispatch(stripePaymentElementErrorEventHandler(state, ev.error))
      return
    }
    stripe
      .confirmPayment({
        elements,
        clientSecret: stripeIntentClientSecret,
        redirect: 'if_required',
      })
      .then(response => {
        if (response.error) {
          dispatch(postCheckoutFailure(response.error.param))
        } else {
          const formData = prepPostData(state, response.paymentIntent.id, null, true)
          return dispatch(postCheckoutFetch(formData, postUrl))
        }
      })
  })
}

export const createStripeIntent = () => (dispatch, getState) => {
  const state = getState()
  const url = `/booking/widget/${state.venueInfo.venueUrlKey}/payment_intent`
  const calced = calcChargesFromState(state)
  const intentForm = new FormData()
  intentForm.append('amount', calced.charge_amount)
  intentForm.append('source', IntentSources[TransactionTypes.WEB_EVENT_CHARGE])

  return new Promise(resolve => {
    let secret = ''
    fetch(url, {
      body: intentForm,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      // eslint-disable-next-line consistent-return
      .then(response => {
        if (response.errors) {
          let messageStringFinal = ''
          response.errors.forEach(errorItem => {
            const messageString = errorItem.split(':')
            messageStringFinal += `* ${messageString[1]}`
          })
          dispatch(postCheckoutFailure(String(messageStringFinal)))
        } else {
          secret = response.payload.client_secret
          dispatch(setIntentClientSecret(secret))
          resolve({ intentClientSecret: secret })
        }
        return secret
      })
  })
}

export const submitCheckoutData = (data, stripe) => (dispatch, getState) => {
  const state = getState()
  const calced = calcChargesFromState(state)
  const postUrl = `${state.widgetSettings.baseUrl}/booking/widget/${state.venueInfo.venueUrlKey}/event_book`

  if (state.venueInfo.paymentType === AccountTypes.STRIPE) {
    dispatch(tryPostCheckout())
    const intentForm = new FormData()
    intentForm.append('amount', calced.charge_amount)
    intentForm.append('source', IntentSources[TransactionTypes.WEB_EVENT_CHARGE])
    return dispatch(stripePaymentMethodSubmitHandler(stripe))
  } else if (state.venueInfo.paymentType === AccountTypes.ADYEN) {
    const formData = prepPostData(getState(), null, data)
    return dispatch(postCheckoutFetch(formData, postUrl))
  } else if (state.venueInfo.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX) {
    const formData = prepPostData(getState(), data.card_token, data)
    return dispatch(postCheckoutFetch(formData, postUrl))
  } else if (state.venueInfo.paymentType === AccountTypes.FREEDOMPAY) {
    const rsaEncryptor = new RSAEncrypt(FREEDOM_PAY_PUBLIC_KEY)
    const encryptedCard = rsaEncryptor.freedomPayCard(data.cardNum, data.cardMonthExp, data.cardYearExp, data.cardCvv)
    const formData = prepPostData(getState(), encryptedCard)
    return dispatch(postCheckoutFetch(formData, postUrl))
  } else if (state.venueInfo.paymentType === AccountTypes.NETWORK) {
    const aesEncryptor = new AESEncrypt(state.widgetSettings.encryptionKey)
    const piper = new PipeFormat()

    const { cardNum, cardMonthExp, cardYearExp, cardCvv, cardFirstName, cardLastName } = data
    const cardHolderName = `${cardFirstName} ${cardLastName}`

    const request = piper.buildRequest(
      cardNum,
      cardMonthExp,
      cardYearExp,
      cardCvv,
      cardHolderName,
      calced.charge_amount,
      'SALE',
      state.venueInfo.urlKey,
      `?event_id=${state.userSelection.eventId}`
    )
    const encryptedRequest = aesEncryptor.encrypt(request)
    const formData = prepPostData(getState(), encryptedRequest)
    formData.process_id = piper.processId

    return dispatch(postCheckoutFetch(formData, postUrl))
  } else if (state.venueInfo.paymentType === AccountTypes.CYBERSOURCE_REDUX) {
    const jwkUrl = `/booking/widget/${state.venueInfo.id}/jwk`
    fetch(jwkUrl, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(resp => {
        if (!resp?.kid) {
          dispatch(postCheckoutFailure(String('Failed to retrieve jwk key')))
        } else {
          const options = {
            kid: resp.kid,
            keystore: resp,
            encryptionType: 'rsaoaep256',
            cardInfo: {
              cardNumber: data.cardNum,
              cardType: CardCodes[stripeCard.cardType(data.cardNum).toUpperCase()],
              cardExpirationMonth: `0${data.cardMonthExp}`.substr(-2),
              cardExpirationYear: String(data.cardYearExp),
            },
            production: state.app.env === 'PRODUCTION',
          }
          flex.createToken(options, response => {
            if (response.error) {
              // eslint-disable-next-line no-alert
              alert('We could not process your card.')
              return
            }
            response.expiryMonth = data.cardMonthExp
            response.expiryYear = data.cardYearExp
            const formData = prepPostData(getState(), response.token, response)
            return dispatch(postCheckoutFetch(formData, postUrl))
          })
        }
      })
  } else if (state.venueInfo.paymentType === AccountTypes.NETWORK_REDUX) {
    const errorMessage = 'Unable to process request. Please try again'
    window.NI.generateSessionId()
      .then(response => {
        const formData = new FormData()
        const formFields = state.formFields.toJS()
        formData.append('session_id', response.session_id)
        formData.append('amount', calced.charge_amount)
        formData.append('first_name', formFields.firstName)
        formData.append('last_name', formFields.lastName)
        formData.append('email_address', formFields.email)
        fetch(`/booking/widget/${state.venueInfo.urlKey}/auth_charge`, {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
        })
          .then(response => {
            if (!response.ok && response.status === 500) {
              // eslint-disable-next-line no-throw-literal
              throw `We encountered an error: ${response.status} ${response.statusText}`
            }
            return response
          })
          .then(response => response.json())
          .then(response => {
            dispatch(dismissModal())
            if (response.errors) {
              throw errorMessage
            }
            const token = [response._id.split(':')[2], response.orderReference].join('|')
            window.NI.handlePaymentResponse(response, {
              mountId: 'cc3dsMountId',
              style: {
                width: 500,
                height: 500,
              },
            }).then(response => {
              const { status } = response
              if (status === window.NI.paymentStates.AUTHORISED || status === window.NI.paymentStates.CAPTURED) {
                const fm = prepPostData(getState(), token)
                dispatch(postCheckoutFetch(fm, postUrl))
              } else if (status === window.NI.paymentStates.FAILED || status === window.NI.paymentStates.THREE_DS_FAILURE) {
                dispatch(postCheckoutFailure(errorMessage))
              }
            })
          })
          .catch(error => {
            dispatch(postCheckoutFailure(String(error)))
          })
      })
      .catch(error => {
        const message = error.message || textCommonPaymentErrorUnexpectedError
        dispatch(postCheckoutFailure(String(message)))
      })
  } else if (state.venueInfo.paymentType === AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE) {
    window.NI.generateSessionId()
      .then(response => {
        const fm = prepPostData(getState(), null)
        fm.session_id = response.session_id
        dispatch(postCheckoutFetch(fm, postUrl))
      })
      .catch(error => {
        const message = error.message || textCommonPaymentErrorUnexpectedError
        dispatch(postCheckoutFailure(String(message)))
      })
  } else if (state.venueInfo.paymentType === AccountTypes.SAFERPAY) {
    const formData = prepPostData(getState(), data?.card_token)
    formData.card_token = data?.card_token
    return dispatch(postCheckoutFetch(formData, postUrl))
  } else if (state.venueInfo.paymentType === AccountTypes.SHIFT_4) {
    const formData = prepPostData(getState(), data?.card_token, data)
    formData.card_token = data?.card_token
    return dispatch(postCheckoutFetch(formData, postUrl))
  } else {
    const cyberEncryptor = new CyberEncrypt()
    const cardData = cyberEncryptor.encrypt(
      [
        state.formFields.get('cardNum'),
        state.formFields.get('cardMonthExp'),
        state.formFields.get('cardYearExp'),
        state.formFields.get('cardCvv'),
      ].join('|')
    )
    const formData = {
      ...prepPostData(state),
      card_data: cardData,
      zip: state.formFields.get('cardZipCode'),
      card_first_name: state.formFields.get('cardFirstName'),
      card_last_name: state.formFields.get('cardLastName'),
    }
    return dispatch(postCheckoutFetch(formData, postUrl))
  }
}

export const submitCheckoutDataNoPayment = () => (dispatch, getState) => {
  const state = getState()
  const formData = prepPostData(state)
  const postUrl = `${state.widgetSettings.baseUrl}/booking/widget/${state.venueInfo.venueUrlKey}/event_book`
  dispatch(tryPostCheckout())
  fetch(postUrl, {
    body: convertToFormatData(formData),
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(() => {
          dispatch(postCheckoutSuccess())
        })
      } else {
        handleErrors(response).then(respBody => dispatch(postCheckoutFailure(respBody)))
      }
    })
    .catch(response => {
      handleErrors(response).then(respBody => dispatch(postCheckoutFailure(respBody)))
    })
}

const fetchRequestData = state => {
  const { inventoryItems } = state.userSelection.toJS()
  const { availabilityId } = inventoryItems[0]

  const timeFormField = state.formFields.get('time')
  let time = ''
  if (parseBoolean(state.widgetSettings.enableFieldTime)) {
    time = state.app.language.toUpperCase() === 'EN-US' ? moment(timeFormField, 'h:mm A').format('HH:mm') : timeFormField
  }
  const requestData = {
    ...fetchUserData(state),
    availability_id: availabilityId,
    num_males: state.formFields.get('numMales'),
    num_females: state.formFields.get('numFemales'),
    num_guests: state.formFields.get('partySize'),
    time,
  }

  return _.pickBy(requestData, Boolean)
}

export const submitRequest = () => (dispatch, getState) => {
  const state = getState()
  const formData = fetchRequestData(state)
  const postUrl = `${state.widgetSettings.baseUrl}/booking/widget/${state.venueInfo.venueUrlKey}/event_book`
  dispatch(tryPostCheckout())
  fetch(postUrl, {
    body: convertToFormatData(formData),
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(() => {
          dispatch(postCheckoutSuccess())
        })
      } else {
        handleErrors(response).then(respBody => dispatch(postCheckoutFailure(respBody)))
      }
    })
    .catch(response => {
      handleErrors(response).then(respBody => dispatch(postCheckoutFailure(respBody)))
    })
}

export const fetchVerifyPromoCode = state => {
  const venueKey = state.venueInfo.venueUrlKey
  const promoCode = state.formFields.get('promoCode')
  const { baseUrl } = state.widgetSettings
  const { eventId, date } = state.userSelection.toJS()
  const startTime = state.entities.events.get(eventId).get('eventStartTime')
  const postUrl = `${baseUrl}/booking/widget/${venueKey}/validate_promo`

  return fetch(postUrl, {
    body: convertToFormData({
      promo_code: promoCode,
      res_date: date,
      res_time: startTime.split(':', 2).join(':'),
      source_entity_id: eventId,
      platform_apply_type: 'EVENT_WIDGET',
    }),
    method: 'POST',
    credentials: 'same-origin',
  }).then(response => response.json())
}

export const submitPaymentCheckoutDataCybersourceThreeDSRedux = () => (dispatch, getState) => {
  const state = getState()
  const formFields = state.formFields.toJS()
  const url = `/booking/widget/${state.venueInfo.id}/jwk`
  dispatch(tryPostCheckout())
  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(resp => {
      if (!resp?.kid) {
        dispatch(postCheckoutFailure(String('Failed to retrieve jwk key')))
      } else {
        const options = {
          kid: resp.kid,
          keystore: resp,
          encryptionType: 'rsaoaep256',
          cardInfo: {
            cardNumber: formFields.cardNum,
            cardType: CardCodes[stripeCard.cardType(formFields.cardNum).toUpperCase()],
            cardExpirationMonth: `0${formFields.cardMonthExp}`.substr(-2),
            cardExpirationYear: String(formFields.cardYearExp),
          },
          production: state.app.env === 'PRODUCTION',
        }
        flex.createToken(options, response => {
          if (response.error) {
            // eslint-disable-next-line no-alert
            alert('We could not process your card.')
            return
          }
          response.expiryMonth = formFields.cardMonthExp
          response.expiryYear = formFields.cardYearExp
          const preppedData = prepPostData(state, response.token, response)
          dispatch(startReduxAuthenticationServiceSetup(preppedData))
        })
      }
    })
}

const startReduxAuthenticationServiceSetup = chargeData => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const url = `${baseUrl}/api-yoa/cybersource/auth/${chargeData.venue_id}/get_initial_data`
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(chargeData),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 200) {
        dispatch(payerAuthenticationSetupComplete(response.data))
      } else {
        dispatch(postCheckoutFailure(response.msg))
      }
    })
    .catch(() => dispatch(postCheckoutFailure('Unable to process request. Please try again')))
}

export const paymentEnrollmentCybersourceThreeDs = (cardToken, cardData) => dispatch => {
  dispatch(submitCheckoutData({ ...cardData, card_token: cardToken }, null))
}

const cleanState = paymentType => dispatch => {
  if (paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX) {
    dispatch(cleanCybersourcePaymentInformation())
  }
}

export const fetchOrRefreshFreedompayDataForIframe = (venueId, paymentMethod, styles) => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const url = `${baseUrl}/api-yoa/payments/${venueId}/begin_payment`

  const calced = calcChargesFromState(state)
  const formData = new FormData()
  const formFields = state.formFields.toJS()

  formData.append('total', calced.charge_amount * 100)
  formData.append('tax', calced.charge_tax_amount * 100)
  formData.append('styles', styles)
  formData.append('payment_method', paymentMethod)

  // will be ignored for non-hpc on BE
  formData.append('address1', formFields?.address1)
  formData.append('city', formFields?.city)
  formData.append('locality', formFields?.locality)
  formData.append('administrativeArea', formFields?.administrativeArea)
  formData.append('countryCode', formFields?.countryCode)
  formData.append('postalCode', formFields?.postalCode)

  formData.append('firstName', `${formFields.firstName}`)
  formData.append('lastName', `${formFields.lastName}`)

  formData.append('email', `${formFields?.email}`)
  formData.append('phone', `${formFields?.phoneNumber}`)
  // will be ignored for non-hpc on BE

  dispatch(setFreedompayIframeInitialized(false))
  fetch(url, {
    body: formData,
    method: 'POST',
  })
    .then(response => response.json())
    .then(response => {
      dispatch(setFreedompayInitialData(response.data.begin_payment))
      dispatch(setFreedompayIframeInitialized(true))
    })
}

export const submitFreedompayPaymentRequest = (venueId, freedompayData, paymentMethod) => (dispatch, getState) => {
  const state = getState()
  const { baseUrl } = state.widgetSettings
  const authUrl = `${baseUrl}/api-yoa/payments/${venueId}/authorize`
  const postUrl = `${state.widgetSettings.baseUrl}/booking/widget/${state.venueInfo.venueUrlKey}/event_book`
  const calced = calcChargesFromState(state)

  const authData = new FormData()
  const attrs = freedompayData.attributes
  const cardFreedompayData = getFreedompayCardData(attrs, freedompayData.paymentType)
  const formFields = state.formFields.toJS()

  authData.append('paymentKey', freedompayData.paymentKeys[0])
  authData.append('sessionKey', state.payment.freedompay.initialData.sessionKey)
  authData.append('total', calced.charge_amount * 100)
  authData.append('tax', calced.charge_tax_amount * 100)
  authData.append('name', `${formFields.cardFirstName} ${formFields.cardLastName}`)
  authData.append('transactionType', TransactionTypes.WEB_EVENT_CHARGE)
  authData.append('paymentMethod', paymentMethod)

  // will be ignored for non-hpc on BE
  authData.append('address1', formFields?.address1)
  authData.append('locality', formFields?.locality)
  authData.append('administrativeArea', formFields?.administrativeArea)
  authData.append('countryCode', formFields?.countryCode)
  authData.append('postalCode', formFields?.postalCode)

  authData.append('firstName', `${formFields.firstName}`)
  authData.append('lastName', `${formFields.lastName}`)

  authData.append('email', `${formFields?.email}`)
  authData.append('phone', `${formFields?.phoneNumber}`)
  // will be ignored for non-hpc on BE

  dispatch(tryPostCheckout())
  fetch(authUrl, {
    body: authData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (response.status !== 200) {
        if (FREEDOMPAY_SOFT_DECLINE_CODES.includes(response.msg)) {
          window.FreedomPay.Apm.ConsumerAuth.invoke({ mandateChallenge: true })
          return
        }
        dispatch(postCheckoutFailure(response.msg))
        dispatch(fetchOrRefreshFreedompayDataForIframe(venueId, paymentMethod))
        return
      }
      const cardData = {
        ...cardFreedompayData,
        transactionId: response.data.authorization.transaction_id,
        invoiceId: response.data.authorization.invoice_id,
        posSyncId: response.data.authorization.pos_sync_id,
        purchaserCode: response.data.authorization.purchaser_code,
        nameOnCard: response.data.authorization.name_on_card,
        sessionKey: response.data.authorization.session_key,
        transactionType: TransactionTypes.WEB_EVENT_CHARGE,
        cofComplianceData: response.data.authorization.cof_compliance_data,
        paymentMethod,
      }
      const preppedCharge = prepPostData(getState(), response.data.authorization.token, cardData)
      return dispatch(postCheckoutFetch(preppedCharge, postUrl))
    })
    .catch(err => {
      dispatch(postCheckoutFailure(err))
      dispatch(fetchOrRefreshFreedompayDataForIframe(venueId, paymentMethod))
    })
}

const getFreedompayCardData = (attrs, paymentType) => {
  let maskedCard
  let brand
  let expirationDate
  let postalCode
  if (paymentType === 'GooglePay') {
    const { info } = attrs.filter(attr => attr.Key === 'Raw')[0]?.Value?.paymentMethodData
    maskedCard = info?.cardDetails
    brand = info?.cardNetwork
  } else if (paymentType === 'ApplePay') {
    const networkNumber = attrs.filter(attr => attr.Key === 'maskedCardNumber')[0]?.Value.split(' ')
    // eslint-disable-next-line prefer-destructuring
    maskedCard = networkNumber[1]
    // eslint-disable-next-line prefer-destructuring
    brand = networkNumber[0]
  } else {
    maskedCard = attrs.find(attr => attr.Key === 'MaskedCardNumber')?.Value
    brand = attrs.find(attr => attr.Key === 'CardIssuer')?.Value
    postalCode = attrs.find(attr => attr.Key === 'PostalCode')?.Value
    expirationDate = attrs.find(attr => attr.Key === 'ExpirationDate')?.Value
    return { maskedCard, brand, postalCode, expirationDate }
  }
  return { maskedCard, brand }
}

export const getFeatureFlags = () => async (dispatch, getState) => {
  const state = getState()
  const provider = await getConfigProvider({
    prefabApiKey: window.widgetInit.prefabKey,
    contexts: {
      venueUrlKey: [state.venueInfo.venueUrlKey],
    },
  })
  const isMultiInventoryTypeEnabled = provider.isEnabled('events.multi_inventory_type')
  const useAdminPanelFloorplanImages = provider.isEnabled('events.use_admin_panel_floorplan_images')
  dispatch({ type: SET_FEATURE_FLAGS, data: { isMultiInventoryTypeEnabled, useAdminPanelFloorplanImages } })
}
