import * as styles from '../../assets/styles/eventOverview'
import QuantityStepper from '../../containers/QuantityStepper'
import { connect } from 'react-redux'
import { incrementItem, selectInventoryItems } from '../../actions/navigation'
import ContinueButton from './ContinueButton'
import { parseBoolean } from '../../utils/preloadedState'
import { calcTotalFeePrice } from '../../utils/calcCharges'
import { RESERVATION_TYPE, GUESTLIST_FREE_TYPE, REQUEST_TYPE } from '../../reducers/inventoryReducer'

const { fonts_color_primary, fonts_color_secondary } = widgetInit.settings

const InventoryItem = ({
  inventoryItem,
  inventoryAvailabilities,
  inventoryCart,
  languageCode,
  currencyCode,
  isDisabled,
  textMinimumSpend,
  isFeesInPriceDisplayed,
  incrementItem,
  selectInventoryItem,
  selectedDate,
  selectedEventId,
  defaultServiceCharge,
}) => {
  const { price, compText, inventoryId, inventoryType, entryPerReservation } = inventoryItem
  const { availabilityId } = inventoryAvailabilities[inventoryId]
  const { quantity } = inventoryCart.find(item => item.inventoryId === inventoryId)
  const numGuests = quantity * entryPerReservation
  const maxQuantity = Math.min(inventoryItem.maxQuantity, inventoryAvailabilities[inventoryId].remainingQuantity)

  const totalFeePrice = calcTotalFeePrice(inventoryItem, 1, price, defaultServiceCharge)
  const totalPrice = isFeesInPriceDisplayed ? price + totalFeePrice : price
  const currencyFormatter = new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode })

  const opacity = isDisabled ? '0.3' : '1'

  const minDollarText = (function () {
    if (!inventoryItem.minPrice) {
      return ''
    }
    const minDollarAmount = quantity * inventoryItem.minPrice
    const minDollarAmountFormatted = currencyFormatter.format(minDollarAmount)
    return `, ${minDollarAmountFormatted} ${textMinimumSpend}`
  })()

  let headerText
  if (numGuests === 0) {
    headerText = ''
  } else if (numGuests === 1) {
    const headerInner = inventoryType === RESERVATION_TYPE ? `For 1 guest${minDollarText}` : 'For 1 guest'
    headerText = <span>{headerInner}</span>
  } else {
    let headerString
    if (inventoryType === RESERVATION_TYPE) {
      headerString = `Up to ${numGuests} guests${minDollarText}`
    } else {
      headerString = `For ${numGuests} guests`
    }
    headerText = <span>{headerString}</span>
  }

  if (inventoryType === REQUEST_TYPE) {
    return (
      <div style={{ backgroundColor: isDisabled ? styles.rgbaWidgetBackground : styles.rgbaCellBackground, padding: '20px 16px' }}>
        <div
          style={{
            position: 'absolute',
            fontSize: '12px',
            fontWeight: '300',
            color: fonts_color_secondary,
            marginTop: '-7px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '506px',
            opacity,
          }}
          data-test="header-text"
        >
          Request only
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '18px',
              color: fonts_color_primary,
              flex: '1 1 auto',
              opacity,
            }}
          >
            {inventoryItem.inventoryName}
          </div>
          <ContinueButton
            aria-label="continue to checkout"
            disabled={isDisabled}
            onClick={() => {
              incrementItem(selectedDate, selectedEventId, inventoryId)
              selectInventoryItem(inventoryId, availabilityId)
            }}
          >
            Request
          </ContinueButton>
        </div>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: isDisabled ? styles.rgbaWidgetBackground : styles.rgbaCellBackground, padding: '20px 16px' }}>
      <div
        style={{
          position: 'absolute',
          fontSize: '12px',
          fontWeight: '300',
          color: fonts_color_secondary,
          marginTop: '-7px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '506px',
          opacity,
        }}
        data-test="header-text"
      >
        {headerText}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            fontSize: '18px',
            color: fonts_color_primary,
            flex: '1 1 auto',
            opacity,
          }}
        >
          {inventoryItem.inventoryName}
        </div>
        <div style={{ flex: '0 1 34%', marginLeft: '8px', marginRight: '148px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <QuantityStepper
              inventoryId={inventoryId}
              quantity={quantity}
              minQuantity={0}
              maxQuantity={maxQuantity}
              isDisabled={isDisabled}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', opacity }}>
              <div style={{ fontSize: '16px', marginLeft: '8px' }} data-test="price-text">
                {inventoryType === GUESTLIST_FREE_TYPE ? compText : currencyFormatter.format(totalPrice)}
              </div>
              {!!(isFeesInPriceDisplayed && totalFeePrice) && (
                <div
                  data-test="fees-in-price"
                  style={{
                    position: 'absolute',
                    fontSize: '12px',
                    fontWeight: '300',
                    color: fonts_color_secondary,
                    marginTop: '30px',
                  }}
                >
                  * Includes {currencyFormatter.format(totalFeePrice)} in fees
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  incrementItem: (date, eventId, inventoryId) => {
    dispatch(incrementItem(date, eventId, inventoryId))
  },
  selectInventoryItem: (inventoryId, availabilityId) => {
    dispatch(selectInventoryItems([{ inventoryId, availabilityId }]))
  },
})
const mapStateToProps = state => ({
  languageCode: state.app.language,
  textMinimumSpend: state.widgetSettings.textMinimumSpend,
  inventoryCart: state.inventoryCart.cart,
  selectedDate: state.userSelection.get('date'),
  selectedEventId: state.userSelection.get('eventId'),
  isFeesInPriceDisplayed: parseBoolean(state.widgetSettings.isFeesInPriceDisplayed),
  defaultServiceCharge: state.venueInfo.venueDefaultServiceCharge,
})
export default connect(mapStateToProps, mapDispatchToProps)(InventoryItem)
