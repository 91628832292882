import { connect } from 'react-redux'
import { stepForwardMonth, stepBackMonth, initScrollAnimation, hoverDate, selectDate } from '../actions/navigation'
import Calendar from '../components/Calendar'

const mapStateToProps = state => ({
  calendarInfo: state.calendar.toJS(),
  venueToday: state.venueInfo.venueToday,
  availability: state.entities.availability.toJS(),
})

const mapDispatchToProps = dispatch => ({
  stepForwardMonth: () => {
    dispatch(stepForwardMonth())
  },
  stepBackMonth: () => {
    dispatch(stepBackMonth())
  },
  hoverDate: date => {
    dispatch(hoverDate(date))
  },
  selectDate: date => {
    dispatch(selectDate(date))
    dispatch(initScrollAnimation(date))
  },
})

const EventCalendar = connect(mapStateToProps, mapDispatchToProps)(Calendar)

export default EventCalendar
