import _ from 'lodash'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import * as styles from '../../assets/styles/eventOverview'
import InventoryItem from './InventoryItem'
import ContinueButton from './ContinueButton'
import { parseBoolean } from '../../utils/preloadedState'
import { calcTotalFeePrice } from '../../utils/calcCharges'
import { RESERVATION_TYPE, GUESTLIST_TYPE, GUESTLIST_FREE_TYPE, REQUEST_TYPE } from '../../reducers/inventoryReducer'

const EventInventoryList = ({
  inventoryData,
  inventoryEdits: inventoryAvailabilities,
  onContinue,
  isPrivate,
  isBookable,
  isEventOpened,
  date,
  languageCode,
  currencyCode,
  tieredEvents,
  isExperienceMode,
  inventoryCart,
  isFeesInPriceDisplayed,
  defaultServiceCharge,
}) => {
  const currencyFormatter = new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode })

  const renderNotAvailableMessage = message => (
    <div
      style={{
        ...styles.inventoryListItem,
        ...styles.largeDevicePadding,
      }}
    >
      <div style={styles.noAvailText}>{message}</div>
    </div>
  )

  let notAvailableMessage
  if (!isEventOpened && _.isEmpty(inventoryData)) {
    let msg = 'Booking for this event has not been opened yet, please check back at a later date.'
    if (date) {
      if (date.indexOf('T') !== -1) {
        const openDate = moment(date).format('MMMM D, YYYY')
        msg = `Booking for this event opens on ${openDate}.`
      }
    }
    notAvailableMessage = msg
  } else if (_.isEmpty(inventoryData) || (!isExperienceMode && isPrivate && !isBookable)) {
    notAvailableMessage = 'This event is sold out.'
  }

  const shouldDisplayInventory = inventoryItem => {
    if (_.isArray(tieredEvents) && tieredEvents.indexOf(inventoryItem.inventoryId) >= 0) {
      return false
    }

    const { inventoryId, inventoryType } = inventoryItem
    const inventoryAvailability = inventoryAvailabilities && inventoryAvailabilities[inventoryId]
    const remainingQuantity = Math.min(
      inventoryItem.maxQuantity,
      inventoryAvailability ? inventoryAvailability.remainingQuantity : inventoryItem.maxQuantity
    )

    if (remainingQuantity <= 0 && inventoryType !== REQUEST_TYPE) {
      return false
    }

    return true
  }

  const inventoryTypes = new Set()
  Object.values(inventoryData).forEach(inventory => {
    if (shouldDisplayInventory(inventory)) {
      if (inventory.inventoryType === GUESTLIST_TYPE || inventory.inventoryType === GUESTLIST_FREE_TYPE) {
        inventoryTypes.add(GUESTLIST_TYPE)
      } else {
        inventoryTypes.add(inventory.inventoryType)
      }
    }
  })
  const hasMoreThanOneInventoryType = inventoryTypes.size > 1
  const hasAtLeastOneItemInCart = inventoryCart.filter(inventoryCartItem => inventoryCartItem.quantity > 0).length > 0

  const renderReservationOrGuestlistTypeInventory = isReservationType => {
    const reservationTypeInventoryData = Object.values(inventoryData).filter(inventory => {
      if (isReservationType) {
        return inventory.inventoryType === RESERVATION_TYPE && shouldDisplayInventory(inventory)
      }

      return (
        (inventory.inventoryType === GUESTLIST_TYPE || inventory.inventoryType === GUESTLIST_FREE_TYPE) && shouldDisplayInventory(inventory)
      )
    })

    reservationTypeInventoryData
      .sort((inventoryA, inventoryB) => inventoryA.inventoryName - inventoryB.inventoryName)
      .sort((inventoryA, inventoryB) => inventoryA.price - inventoryB.price)

    const filteredInventoryCart = inventoryCart.filter(
      inventoryCartItem => !!reservationTypeInventoryData.find(inventory => inventory.inventoryId === inventoryCartItem.inventoryId)
    )

    const totalPrice = filteredInventoryCart.reduce((total, inventoryCartItem) => {
      const reservationTypeInventory = reservationTypeInventoryData.find(
        inventory => inventory.inventoryId === inventoryCartItem.inventoryId
      )
      const totalFeePrice = calcTotalFeePrice(reservationTypeInventory, 1, reservationTypeInventory.price, defaultServiceCharge)
      const totalPrice = isFeesInPriceDisplayed ? reservationTypeInventory.price + totalFeePrice : reservationTypeInventory.price
      return total + inventoryCartItem.quantity * totalPrice
    }, 0)

    const hasAtLeastOneItemOfThisTypeInCart = filteredInventoryCart.filter(inventoryCartItem => inventoryCartItem.quantity > 0).length > 0
    const isDisabled = !hasAtLeastOneItemOfThisTypeInCart && hasAtLeastOneItemInCart
    const opacity = isDisabled ? '0.3' : '1'

    if (reservationTypeInventoryData.length === 0) {
      return null
    }

    return (
      <section style={{ backgroundColor: styles.rgbaLineBackground }}>
        {hasMoreThanOneInventoryType && (
          <>
            <hr style={styles.inventoryItemSeperator} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ fontSize: '18px', fontWeight: 400, margin: 0, padding: '16px' }}>
                {isReservationType ? 'General' : 'Guestlist'}
              </h2>
              {isDisabled && <div style={{ fontSize: '16px', marginRight: '16px' }}>Deselect inventory from other categories</div>}
            </div>
            <hr style={styles.inventoryItemSeperator} />
          </>
        )}
        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
          {reservationTypeInventoryData.map(inventoryItem => (
            <li key={inventoryItem.id} style={{ borderBottom: `solid 1px ${styles.rgbaLineBackground}` }}>
              <InventoryItem
                inventoryItem={inventoryItem}
                inventoryAvailabilities={inventoryAvailabilities}
                currencyCode={currencyCode}
                isDisabled={isDisabled}
              />
            </li>
          ))}
        </ul>
        <div
          style={{
            backgroundColor: isDisabled ? styles.rgbaWidgetBackground : styles.rgbaCellBackground,
            padding: '20px 16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: '18px', fontWeight: 600, opacity }}>Total Price</div>
          <div
            style={{
              fontSize: '16px',
              fontWeight: 600,
              flex: '1 1 auto',
              textAlign: 'right',
              marginRight: '18px',
              opacity,
            }}
          >
            {currencyFormatter.format(totalPrice)}
          </div>
          <ContinueButton
            aria-label="continue to checkout"
            disabled={!hasAtLeastOneItemOfThisTypeInCart}
            onClick={() => {
              const inventories = filteredInventoryCart.map(inventoryCartItem => ({
                ...inventoryCartItem,
                availabilityId: inventoryAvailabilities[inventoryCartItem.inventoryId].availabilityId,
              }))

              onContinue(inventories)
            }}
          >
            Continue
          </ContinueButton>
        </div>
      </section>
    )
  }

  const renderRequestInventory = () => {
    const isDisabled = hasAtLeastOneItemInCart
    const requestInventoryData = Object.values(inventoryData).filter(
      inventory => inventory.inventoryType === REQUEST_TYPE && shouldDisplayInventory(inventory)
    )

    requestInventoryData.sort((inventoryA, inventoryB) => inventoryA.inventoryName - inventoryB.inventoryName)

    if (requestInventoryData.length === 0) {
      return null
    }

    return (
      <section style={{ backgroundColor: styles.rgbaLineBackground }}>
        {hasMoreThanOneInventoryType && (
          <>
            <hr style={styles.inventoryItemSeperator} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ fontSize: '18px', fontWeight: 400, margin: 0, padding: '16px' }}>Requests</h2>
              {isDisabled && <div style={{ fontSize: '16px', marginRight: '16px' }}>Deselect inventory from other categories</div>}
            </div>

            <hr style={styles.inventoryItemSeperator} />
          </>
        )}
        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
          {_.map(requestInventoryData, inventoryItem => (
            <li key={inventoryItem.id} style={{ borderBottom: `solid 1px ${styles.rgbaLineBackground}` }}>
              <InventoryItem
                inventoryItem={inventoryItem}
                inventoryAvailabilities={inventoryAvailabilities}
                currencyCode={currencyCode}
                isDisabled={isDisabled}
              />
            </li>
          ))}
        </ul>
      </section>
    )
  }

  return (
    <div style={styles.inventoryWrapper}>
      {notAvailableMessage ? (
        renderNotAvailableMessage(notAvailableMessage)
      ) : (
        <>
          <div>{renderReservationOrGuestlistTypeInventory(true)}</div>
          <div>{renderReservationOrGuestlistTypeInventory(false)}</div>
          <div>{renderRequestInventory()}</div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  languageCode: state.app.language,
  inventoryCart: state.inventoryCart.cart,
  isFeesInPriceDisplayed: parseBoolean(state.widgetSettings.isFeesInPriceDisplayed),
  defaultServiceCharge: state.venueInfo.venueDefaultServiceCharge,
})
export default connect(mapStateToProps)(EventInventoryList)
