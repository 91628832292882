import { Map } from 'immutable'
import { camelCaseObject } from '../../../../common/ObjectUtils'
import { VERIFY_PROMO_CODE_SUCCESS, REMOVE_PROMO_CODE } from '../actions/ActionTypes'

const promoCode = (state = null, action) => {
  switch (action.type) {
    case VERIFY_PROMO_CODE_SUCCESS:
      return camelCaseObject(action.data)
    case REMOVE_PROMO_CODE:
      return null
    default:
      return state
  }
}

export default promoCode
