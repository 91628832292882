import { Map } from 'immutable'
import { STEP_FORWARD_MONTH, STEP_BACK_MONTH, HOVER_DATE, HIGHLIGHT_DATE, UNHIGHLIGHT_DATE } from '../actions/ActionTypes'

const currentDate = new Date()
const initialState = Map({
  currentDay: currentDate.getDate(),
  currentMonth: currentDate.getMonth(),
  currentYear: currentDate.getFullYear(),
  calendarMonth: currentDate.getMonth(),
  calendarYear: currentDate.getFullYear(),
  hoverNum: null,
  selectedDate: null,
})
const calendar = (state = initialState, action) => {
  switch (action.type) {
    case STEP_FORWARD_MONTH: {
      const nextMonth = state.get('calendarMonth') + 1
      return nextMonth !== 12
        ? state.merge({ calendarMonth: nextMonth })
        : state.merge({
            calendarMonth: 0,
            calendarYear: state.get('calendarYear') + 1,
          })
    }
    case STEP_BACK_MONTH: {
      const prevMonth = state.get('calendarMonth') - 1
      return prevMonth !== -1
        ? state.merge({ calendarMonth: prevMonth })
        : state.merge({
            calendarMonth: 11,
            calendarYear: state.get('calendarYear') - 1,
          })
    }
    case HOVER_DATE:
      return state.set('hoverNum', action.date)
    case HIGHLIGHT_DATE:
      return state.set('selectedDate', action.date)
    case UNHIGHLIGHT_DATE:
      return state.update('selectedDate', date => (date === action.date ? null : date))
    default:
      return state
  }
}

export default calendar
