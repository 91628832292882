/* eslint react/prop-types: 0, react/jsx-no-bind: 0, jsx-a11y/no-static-element-interactions: 0 */
/* global widgetInit */
import _ from 'lodash'
import React from 'react'
import { AccessibleDivComponent } from 'svr/common/A11y'
import * as styles from '../assets/styles/calendar'
import { hexToRgb } from '../utils/color'
import { daysOfTheWeekShort, daysInMonth, firstDateOfMonth, dateCompare, getStrFromDate, padInt, numRowsInMonth } from '../utils/date'

const CalendarBody = ({ availability, venueToday, calendarInfo, hoverDate, selectDate }) => {
  const { calendarMonth, calendarYear, hoverNum } = calendarInfo
  const numRows = numRowsInMonth(calendarMonth + 1, calendarYear)
  const hoverColor = hexToRgb(widgetInit.settings.color_hover_outline, widgetInit.settings.color_hover_outline_opacity)

  const dayHeader = daysOfTheWeekShort.map(day => (
    <div style={styles.dayLabel} key={day}>
      {day}
    </div>
  ))

  const onDateClick = day => {
    const date = `${calendarYear}-${padInt(calendarMonth + 1)}-${padInt(day)}`
    selectDate(date)
  }

  const numFill = firstDateOfMonth(calendarMonth, calendarYear)
  let emptyCells = []
  if (numFill > 0) {
    emptyCells = _.reduce(
      _.range(numFill),
      (arr, idx) =>
        arr.concat([
          <div style={styles.cellStyle} key={`${idx}f`}>
            <div style={styles.calNumNotAvail}>&nbsp;</div>
          </div>,
        ]),
      []
    )
  }

  const getCalItem = (day, availFlag) => {
    const calItemStyle = availFlag ? { ...styles.calNumAvail, fontWeight: '500' } : styles.calNumNotAvail
    return <div style={calItemStyle}>{day}</div>
  }

  const getCircle = (dateEquality, day, availFlag) => {
    let circleStyle
    if (dateEquality !== 0) {
      circleStyle = {
        ...styles.currentCircle,
        border: '2px solid transparent',
      }
    } else {
      circleStyle = styles.currentCircle
    }
    if (day === hoverNum && availFlag) {
      circleStyle = _.assign({}, circleStyle, {
        border: `2px solid ${hoverColor}`,
      })
    }
    return <div id={`day-${day}`} style={circleStyle} onMouseEnter={hoverDate.bind(null, day)} onMouseLeave={hoverDate.bind(null, null)} />
  }

  const currentDate = new Date(venueToday)
  const numDays = daysInMonth(calendarMonth, calendarYear)
  let dateEquality = -1
  const dayCells = _.map(_.range(numDays), i => {
    const idx = i + 1
    const calDate = new Date(calendarYear, calendarMonth, idx)
    const calDateStr = getStrFromDate(calDate)
    dateEquality = dateEquality === 1 ? 1 : dateCompare(currentDate, calDate)
    const [todayMonth, todayDay, todayYear] = venueToday.split('/')
    const venueTodayStr = `20${todayYear}-${todayMonth}-${todayDay}`
    const availFlag = !!availability[calDateStr] && calDateStr >= venueTodayStr
    const cellStyle = numRows !== 6 ? _.assign({}, styles.cellStyle, { height: '20%' }) : styles.cellStyle
    if (availFlag) {
      return (
        <AccessibleDivComponent
          key={idx}
          style={cellStyle}
          onClick={onDateClick.bind(null, idx)}
          role={availFlag ? 'button' : 'presentation'}
          tabIndex={availFlag ? '0' : '-1'}
        >
          {getCalItem(idx, availFlag)}
          {getCircle(dateEquality, idx, availFlag)}
        </AccessibleDivComponent>
      )
    }
    return (
      <div key={idx} style={cellStyle}>
        {getCalItem(idx, availFlag)}
        {getCircle(dateEquality, idx, availFlag)}
      </div>
    )
  })

  const getCalHeight = numRows === 6 ? styles.calendarWrapper : _.assign({}, styles.calendarWrapper, { paddingBottom: '69.33333%' })

  const getExtraPadding = numRows === 6 ? styles.calendarPad : _.assign({}, styles.calendarPad, { paddingBottom: '2%' })

  return (
    <div>
      <div style={styles.headerStyle}>{dayHeader}</div>
      <div style={getExtraPadding}>
        <div style={getCalHeight}>
          <div style={styles.calendarBody}>{emptyCells.concat(dayCells)}</div>
        </div>
      </div>
    </div>
  )
}

export default CalendarBody
