const fontsColorPrimary = widgetInit.settings.fonts_color_primary
const stepBoxStyles = {
  height: '30px',
  width: '30px',
  borderRadius: '4px',
  border: `solid 1px ${fontsColorPrimary}`,
  margin: '8px 0px',
  padding: '0px 9px',
  color: fontsColorPrimary,
  backgroundColor: 'transparent',
}

const Stepper = ({
  inventory,
  quantity,
  maxQuantity,
  minQuantity,
  inventoryId,
  boundIncrementItem,
  boundDecrementItem,
  isDisabled,
  styleObj,
}) => {
  const inventoryItemName = inventory[inventoryId].inventoryName
  const isMinusButtonDisabled = quantity === minQuantity || isDisabled
  const isPlusButtonDisabled = quantity === maxQuantity || isDisabled

  const minusStepBox = {
    ...stepBoxStyles,
    opacity: isMinusButtonDisabled ? '0.3' : '1',
    cursor: isMinusButtonDisabled ? 'not-allowed' : 'pointer',
  }
  const plusStepBox = {
    ...stepBoxStyles,
    opacity: isPlusButtonDisabled ? '0.3' : '1',
    cursor: isPlusButtonDisabled ? 'not-allowed' : 'pointer',
  }

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center', userSelect: 'none', ...styleObj }}>
      <button
        type="button"
        style={minusStepBox}
        onClick={e => {
          e.stopPropagation()
          if (!isMinusButtonDisabled) {
            boundDecrementItem(inventoryId)
          }
        }}
        aria-label="decrement"
        aria-disabled={isMinusButtonDisabled}
      >
        <span style={{ fontSize: '24px', width: '24px', position: 'relative', left: '-7px' }} className="fa fa-minus" />
      </button>
      <div
        style={{
          width: '30px',
          margin: '0 3px',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: '14px',
          letterSpacing: '1px',
          color: fontsColorPrimary,
          marginTop: '2px',
          opacity: isDisabled ? '0.3' : '1',
        }}
        aria-label={`${inventoryItemName}, quantity`}
        role="alert"
        data-test="stepper-quantity"
      >
        {quantity}
      </div>
      <button
        type="button"
        style={plusStepBox}
        onClick={e => {
          e.stopPropagation()
          if (!isPlusButtonDisabled) {
            boundIncrementItem(inventoryId)
          }
        }}
        aria-label="increment"
        aria-disabled={isPlusButtonDisabled}
      >
        <span style={{ fontSize: '24px', width: '24px', position: 'relative', left: '-7px' }} className="fa fa-plus" />
      </button>
    </div>
  )
}

export default Stepper
