import { SET_FEATURE_FLAGS } from '../actions/ActionTypes'

interface FeatureFlagsState {
  isMultiInventoryTypeEnabled: boolean
}

interface FeatureFlagsAction {
  type: string
  data: FeatureFlagsState
}

const featureFlags = (
  state: FeatureFlagsState = {
    isMultiInventoryTypeEnabled: false,
  },
  action: FeatureFlagsAction
) => {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default featureFlags
