/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
// eslint-disable-next-line no-redeclare
/* global widgetInit, document */
import _ from 'lodash'
import React from 'react'
import { adaptPhotoData } from 'widget/events/utils/photoAdapters'
import * as styles from '../assets/styles/eventList'
import { hexToRgb } from '../utils/color'
import { getDayName, getMonthName } from '../utils/date'
import ListItem from './ListItem'
import Analytics from '../services/AnalyticsServices'

const List = ({ events, availability, numDatesDisplayed, offset, topBody, selectEvent, baseUrl, venueToday, selectedDate, queryObj }) => {
  const mapDataToList = () => {
    let prevMonth = null
    let eventMap
    let first = true
    const sortedDates = _.slice(_.sortBy(_.keys(availability)), 0, numDatesDisplayed)
    return _.reduce(
      sortedDates,
      (jsxList, date) => {
        if (date < venueToday) {
          return jsxList
        }
        eventMap = availability[date]
        const sortedEvents = Object.keys(eventMap).sort((key1, key2) => {
          const event1 = events[key1]
          const event2 = events[key2]
          return event1.eventStartTime.localeCompare(event2.eventStartTime) || event1.name.localeCompare(event2.name)
        })

        const dateArr = date.split('-')
        const [dateYear, dateMonth] = dateArr
        const dateDay = dateArr[2][0] === '0' ? dateArr[2][1] : dateArr[2]
        const monthName = getMonthName(dateMonth - 1)
        const dayName = getDayName('short', dateYear, dateMonth - 1, dateDay)

        let idx = 0
        return jsxList.concat(
          _.reduce(
            sortedEvents,
            (eventList, eventId) => {
              const event = events[eventId]
              if (
                event.privateEvent &&
                event.experienceId &&
                queryObj.experienceId !== event.experienceId &&
                queryObj.eventId !== eventId
              ) {
                return eventList
              }
              const nextMonthFlag = dateMonth !== prevMonth && prevMonth !== null
              prevMonth = dateMonth
              let id = date
              if (idx > 0) {
                id += `-${idx}`
              }
              const rgbLine = hexToRgb(widgetInit.settings.color_lines, widgetInit.settings.color_lines_opacity)
              const monthWrapperStyle = first
                ? _.assign({}, styles.monthWrapper, {
                    borderTop: `2px solid ${rgbLine}`,
                    borderBottom: `2px solid ${rgbLine}`,
                  })
                : styles.monthWrapper
              first = false
              const monthSeperator = nextMonthFlag ? (
                <div style={monthWrapperStyle}>
                  <span style={styles.monthText}>{monthName}</span>
                </div>
              ) : null

              const dateText = `${monthName} ${dateDay}, ${dateYear}`
              const eventLabel = events[eventId].name
              const { src: thumbSrc, srcFallback: thumbFallback, cropData } = adaptPhotoData(events[eventId].photoMap[1], 'medium', baseUrl)

              const onEventItemClick = () => {
                Analytics.selectEvent(date, eventId, eventLabel)
                selectEvent.bind(null, date, eventId)()
              }
              idx += 1
              return eventList.concat(
                <ListItem
                  key={id}
                  id={id}
                  monthSeperator={monthSeperator}
                  nextMonthFlag={nextMonthFlag}
                  onEventItemClick={onEventItemClick}
                  dayName={dayName}
                  dateText={dateText}
                  eventLabel={eventLabel}
                  thumbSrc={thumbSrc}
                  selectedDate={selectedDate}
                  thumbFallback={thumbFallback}
                  interactiveFloorplanUrl={event.interactiveFloorplanUrl}
                  cropData={cropData}
                />
              )
            },
            []
          )
        )
      },
      []
    )
  }

  const getTopButton = () => {
    const eventListOffset = (document.getElementById('event-list') || {}).offsetTop + 6
    return eventListOffset && offset >= eventListOffset ? (
      <div style={styles.topButton} onClick={topBody}>
        <span style={styles.topText}>TOP</span>
      </div>
    ) : null
  }

  const mappedEvents = _.isEmpty(events) ? null : mapDataToList()
  return (
    <div id="event-list" style={styles.listWrapper}>
      {getTopButton()}
      {mappedEvents}
    </div>
  )
}

export default List
