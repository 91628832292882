export function getSelectedInventoryItems(state) {
  return state.inventoryCart.cart
    .filter(({ quantity }) => quantity > 0)
    .map(selectedInventoryItem => {
      const inventory = state.entities.inventory.get(selectedInventoryItem.inventoryId).toJS()
      return {
        ...selectedInventoryItem,
        inventoryName: inventory.inventoryName,
        inventoryType: inventory.inventoryType,
        price: inventory.price,
        minPrice: inventory.minPrice,
        entryPerReservation: inventory.entryPerReservation,
        chargeGratuity: inventory.chargeGratuity,
        chargeGratuityType: inventory.gratuityAmountType,
        tipPercent: inventory.gratuityAmount,
        applyServiceCharge: inventory.applyServiceCharge,
        chargeServiceCharge: inventory.chargeServiceCharge,
        chargeServiceChargeType: inventory.serviceChargeAmountType,
        chargeAdditionalFee: inventory.chargeAdditionalFee,
        chargeAdditionalFeeType: inventory.additionalFeeAmountType,
        additionalFee: inventory.additionalFee,
        chargeProcessing: inventory.adminFeeType === 'CONSUMER',
        enablePromocodes: inventory.enablePromocodes,
        compText: inventory.compText,
      }
    })
}
