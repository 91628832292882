import _ from 'lodash'
import { Component } from 'react'
import { connect } from 'react-redux'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import { validateNotEmpty, validateEmail, validateBirthdayDayMonthFormat } from '../../../../common/Validators'
import PhoneNumber from '../../../../component-lib/Widget/PhoneNumber'
import TextAreaInput from '../../../../lib/TextInputs/TextAreaInput'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { changePhoneNum, changeFormField, changeFlag } from '../actions/forms'
import * as styles from '../assets/styles/checkout'
import { getDateFormattedShort, getLocaleDateFormat } from '../utils/date'
import RequestField from './RequestField'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { parseBoolean } from 'widget/events/utils/preloadedState'

const birthdayValidator = (birthday, locale) => validateBirthdayDayMonthFormat(birthday, getLocaleDateFormat(locale))

class CheckoutInformation extends Component {
  static limitStrToNum(input, numLimit) {
    if (input === '') {
      return ''
    }
    const match = input.match(/([0-9]+)/g)
    return match ? match.pop().substring(0, numLimit) : null
  }

  constructor(props) {
    super(props)
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'firstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'lastName')
    this.handleBirthdayChange = this.props.changeFormField.bind(this, 'birthday')
    this.handleMessageChange = this.props.changeFormField.bind(this, 'message')
    this.handleEmailChange = this.props.changeFormField.bind(this, 'email')
    this.handleTimeChange = this.props.changeFormField.bind(this, 'time')
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this)
    this.handleFlagChange = this.handleFlagChange.bind(this)
    this.handleNumMalesChange = this.props.changeFormField.bind(this, 'numMales')
    this.handleNumFemalesChange = this.props.changeFormField.bind(this, 'numFemales')
    this.handlePartySizeChange = this.props.changeFormField.bind(this, 'partySize')
    this.handleSalutationTypeChange = this.props.changeFormField.bind(this, 'salutation')
  }

  handleFlagChange(phoneNumber, countryCode) {
    this.props.changeFlag(phoneNumber, countryCode)
  }

  handlePhoneNumberChange(phoneNumber, dialCode, formattedNumber) {
    this.props.changePhoneNum(formattedNumber)
  }

  getPartySizeFields() {
    const { colorLines, colorCheckoutCellBackground, infoFields, formErrors, textInputProps } = this.props

    if (this.props.widgetSettings.partySizeType === 'MALE_FEMALE') {
      return (
        <div
          style={_.assign({}, styles.formLine, {
            backgroundColor: colorCheckoutCellBackground,
          })}
        >
          <hr
            style={_.assign({}, styles.formLineSeperator, {
              borderColor: colorLines,
            })}
          />
          <div
            style={_.assign({}, styles.formLine, {
              backgroundColor: colorCheckoutCellBackground,
            })}
          >
            <div style={styles.formBox}>
              <TextInput
                limitType="onlynumbers"
                charLimit={3}
                value={this.props.numMales}
                validator={validateNotEmpty}
                isValid={!formErrors.numMales}
                onChange={this.handleNumMalesChange}
                placeholder="Males *"
                icon="fa fa-users fa-fw"
                iconStyle={styles.partySizeIcon}
                inputStyle={styles.partySizePadding}
                ref={input => {
                  infoFields.numMales = input
                }}
                {...textInputProps}
              />
            </div>
            <div style={styles.formBox}>
              <TextInput
                limitType="onlynumbers"
                charLimit={3}
                value={this.props.numFemales}
                validator={validateNotEmpty}
                isValid={!formErrors.numFemales}
                onChange={this.handleNumFemalesChange}
                placeholder="Females *"
                icon="fa fa-users fa-fw"
                iconStyle={styles.partySizeIcon}
                inputStyle={styles.partySizePadding}
                ref={input => {
                  infoFields.numFemales = input
                }}
                {...textInputProps}
              />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        style={_.assign({}, styles.formLine, {
          backgroundColor: colorCheckoutCellBackground,
        })}
      >
        <hr
          style={_.assign({}, styles.formLineSeperator, {
            borderColor: colorLines,
          })}
        />
        <div
          style={_.assign({}, styles.formLine, {
            backgroundColor: colorCheckoutCellBackground,
          })}
        >
          <div style={styles.formBox}>
            <TextInput
              limitType="onlynumbers"
              charLimit={3}
              value={this.props.partySize}
              validator={validateNotEmpty}
              isValid={!formErrors.partySize}
              onChange={this.handlePartySizeChange}
              placeholder="Party Size *"
              icon="fa fa-users fa-fw"
              iconStyle={styles.partySizeIcon}
              style={styles.partySizeSingleInput}
              inputStyle={styles.partySizePadding}
              ref={input => {
                infoFields.partySize = input
              }}
              {...textInputProps}
            />
          </div>
        </div>
      </div>
    )
  }

  getRequestFields() {
    const {
      colorLines,
      colorCheckoutCellBackground,
      fontsColorPrimary,
      fontsColorCheckoutActive,
      selectedDate,
      formErrors,
      textInputProps,
    } = this.props
    const isTimeValid = formErrors.time

    return (
      <div
        style={_.assign({}, styles.formLine, {
          backgroundColor: colorCheckoutCellBackground,
        })}
      >
        <hr
          style={_.assign({}, styles.formLineSeperator, {
            borderColor: colorLines,
          })}
        />
        <div
          id="sr-time-field"
          style={_.assign({}, styles.formLine, textInputProps, {
            color: fontsColorCheckoutActive,
            backgroundColor: colorCheckoutCellBackground,
          })}
        >
          <div style={_.assign({}, styles.defaultFormBox, styles.dateBox)}>
            <i style={styles.partySizeIcon} className="fa fa-calendar-o fa-fw" aria-hidden="true" />
            <div style={styles.inlineText}>{getDateFormattedShort(selectedDate)}</div>
          </div>
          {this.props.widgetSettings.enableFieldTime ? (
            <div style={_.assign({}, styles.formBox, isTimeValid ? styles.errorBorder : {})}>
              <i style={_.assign({}, styles.timeIcon, { fontSize: '22px' })} className="fa fa-time-8 fa-fw" aria-hidden="true" />
              <RequestField
                type="time"
                value={this.props.time}
                isMobile={this.props.isMobile}
                handleFieldChange={this.handleTimeChange}
                eventStartDate={this.props.eventStartDate}
                eventStartTime={this.props.eventStartTime}
                eventEndDate={this.props.eventEndDate}
                eventEndTime={this.props.eventEndTime}
                fieldIsInvalid={this.props.formErrors.time}
                language={this.props.language}
                divStyle={styles.timeBox}
                fontsColorPrimary={fontsColorPrimary}
                colorCellBackground={this.props.widgetSettings.colorCellBackground}
                colorHover={this.props.widgetSettings.colorHoverOutline}
                colorHighlightRow={this.props.widgetSettings.colorButton}
                colorHighlightRowText={this.props.widgetSettings.fontsColorButton}
              />
            </div>
          ) : null}
        </div>
        {this.getPartySizeFields()}
      </div>
    )
  }

  getDialCode(countryCode) {
    return allCountries[iso2Lookup[countryCode]].dialCode
  }

  phoneNumberToStr(phoneNumber) {
    let formattedNumber = phoneNumber.replace(/[^0-9]/g, '')
    const dialCode = this.getDialCode(this.props.phoneNumberLocale)
    if (formattedNumber.startsWith(dialCode)) {
      // Phone number without the dial code
      formattedNumber = formattedNumber.substr(dialCode.length)
    }
    return formattedNumber
  }

  bottomBorderStyle(lastField) {
    return lastField ? styles.bottomBorderRadius : null
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      birthdayType,
      salutationType,
      birthday,
      phoneNumberLocale,
      phoneNumber,
      messagePlaceholder,
      isRequest,
      enableFieldAdditionalInfo,
      formErrors,
      firstNameLocked,
      lastNameLocked,
      emailLocked,
      phoneLocked,
      topElementId,
      infoFields,
      fontsColorPrimary,
      colorLines,
      colorCheckoutCellBackground,
      textInputProps,
      hideCountryFlags,
      venueLocale,
      salutationOptions,
      salutation,
      fontsColorCheckoutInactive,
      fontsColorError,
      colorBackground,
      colorWidgetBackground,
      colorPrimary,
      widgetTemplateTheme,
      colorCellBackground,
    } = this.props
    let hasPresetCustomSalutation = false
    const salutationOptionsWithCustomChoices = salutationOptions.map(salutationOption => ({
      name: salutationOption,
      value: salutationOption,
    }))
    if (salutation && !salutationOptionsWithCustomChoices.some(salutationOption => salutationOption.value === salutation)) {
      hasPresetCustomSalutation = true
      salutationOptionsWithCustomChoices.push({ name: salutation, value: salutation })
    }
    const { fontFamily } = textInputProps

    return (
      <div id={topElementId} style={styles.sectionWrapper}>
        <div style={styles.labelWrapper}>
          <span
            style={_.assign({}, styles.sectionLabel, {
              color: fontsColorPrimary,
            })}
          >
            Your information
          </span>
        </div>
        <div style={styles.infoForm}>
          {salutationType !== 'Hidden' && (
            <>
              <div
                style={_.assign(
                  {},
                  styles.formLine,
                  styles.topBorderRadius,
                  {
                    backgroundColor: colorCheckoutCellBackground,
                  },
                  formErrors.salutation ? styles.invalidPhoneNumber : null
                )}
              >
                <div>
                  <div style={_.assign({}, styles.titleDropDownLabel)}>
                    <div
                      style={_.assign({}, styles.formBox, {
                        color: formErrors.salutation ? fontsColorError : fontsColorCheckoutInactive,
                        fontSize: '14px',
                        paddingLeft: '10px',
                      })}
                    >
                      {`Title${salutationType === 'Required' ? ' *' : ''}`} :
                    </div>
                    <div>
                      <DropdownArrowsPicker
                        key="salutation"
                        id="salutation"
                        disabled={hasPresetCustomSalutation}
                        name={`Title${salutationType === 'Required' ? ' *' : ''}`}
                        placeholder="Select"
                        style={{
                          textAlign: 'left',
                          color: fontsColorCheckoutInactive,
                          outline: '0',
                          border: 'none',
                        }}
                        choices={salutationOptionsWithCustomChoices}
                        value={salutation}
                        validator={salutationType === 'Required' ? ValidatorTypes.notEmpty : undefined}
                        onChangeHandler={this.handleSalutationTypeChange}
                        showCaret
                        noHeader
                        height={31}
                        ariaDescriptor="Select title"
                        customStyle={{
                          colorLines,
                          fontsColorPrimary,
                          colorBackground,
                          colorWidgetBackground,
                          colorPrimary,
                          widgetTemplateTheme,
                          colorCellBackground,
                        }}
                        borderAreaStyle={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          borderRadius: '0px',
                        }}
                        optionsContainerStyle={{
                          border: 'none',
                        }}
                        ref={input => {
                          infoFields.salutation = input?.instanceRef
                        }}
                        testId="sr-salutation-field"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
            </>
          )}
          <div
            style={_.assign({}, styles.formLine, salutationType === 'Hidden' ? styles.topBorderRadius : null, {
              backgroundColor: colorCheckoutCellBackground,
            })}
          >
            <div style={styles.formBox}>
              <TextInput
                charLimit={50}
                value={firstName}
                validator={validateNotEmpty}
                isValid={!formErrors.firstName}
                onChange={this.handleFirstNameChange}
                autoComplete="given-name"
                placeholder="First Name *"
                style={[styles.firstNameInput, salutationType === 'Hidden' ? { borderRadius: '8px 0 0 0' } : { borderRadius: 0 }]}
                inputStyle={firstNameLocked ? styles.readOnlyInputText : {}}
                ref={input => {
                  infoFields.firstName = input
                }}
                {...textInputProps}
              />
            </div>
            <div style={styles.formBox}>
              <TextInput
                charLimit={50}
                value={lastName}
                validator={validateNotEmpty}
                isValid={!formErrors.lastName}
                onChange={this.handleLastNameChange}
                placeholder="Last Name *"
                style={[styles.lastNameInput, salutationType === 'Hidden' ? { borderRadius: '0 8px 0 0' } : { borderRadius: 0 }]}
                inputStyle={lastNameLocked ? styles.readOnlyInputText : {}}
                ref={input => {
                  infoFields.lastName = input
                }}
                {...textInputProps}
              />
            </div>
          </div>
          <hr
            style={_.assign({}, styles.formLineSeperator, {
              borderColor: colorLines,
            })}
          />
          <div
            id="email-input"
            style={_.assign({}, styles.formLine, {
              backgroundColor: colorCheckoutCellBackground,
            })}
          >
            <TextInput
              value={email}
              validator={validateEmail}
              isValid={!formErrors.email}
              onChange={this.handleEmailChange}
              placeholder="Email Address *"
              style={styles.singleFieldInput}
              inputStyle={emailLocked ? styles.readOnlyInputText : {}}
              ref={input => {
                infoFields.email = input
              }}
              {...textInputProps}
            />
          </div>
          <hr
            style={_.assign({}, styles.formLineSeperator, {
              borderColor: colorLines,
            })}
          />
          <div
            id="phone-num-input"
            style={_.assign(
              {},
              styles.formLine,
              this.bottomBorderStyle(birthdayType === 'Hidden'),
              formErrors.phoneNumber ? styles.invalidPhoneNumber : null,
              { backgroundColor: colorCheckoutCellBackground }
            )}
          >
            <PhoneNumber
              onPhoneNumberChange={this.handlePhoneNumberChange}
              onFlagChange={this.handleFlagChange}
              dialCode={this.getDialCode(phoneNumberLocale)}
              fontFamily={fontFamily}
              isValid={!formErrors.phoneNumber}
              phoneNumber={this.phoneNumberToStr(phoneNumber)}
              selectedCountryCode={phoneNumberLocale}
              customStyles={[phoneLocked ? styles.readOnlyInputText : null]}
              customInputStyles={phoneLocked ? styles.readOnlyInputText : null}
              readOnly={phoneLocked}
              hideFlags={hideCountryFlags}
              ref={input => {
                infoFields.phoneNumber = input
              }}
              placeholderText="Phone Number"
              {...textInputProps}
            />
          </div>
          {isRequest ? this.getRequestFields() : null}
          {birthdayType !== 'Hidden' ? (
            <div>
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
              <div
                style={_.assign({}, styles.formLine, this.bottomBorderStyle(parseBoolean(enableFieldAdditionalInfo) && isRequest), {
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <TextInput
                  placeholder={birthdayType === 'Required' ? 'Birthday *' : 'Birthday'}
                  onFocusPlaceholder={getLocaleDateFormat(venueLocale)}
                  style={styles.singleFieldInput}
                  value={birthday}
                  charLimit={5}
                  validator={birthday => birthdayValidator(birthday, venueLocale)}
                  isValid={!formErrors.birthday}
                  onChange={this.handleBirthdayChange}
                  ref={input => {
                    infoFields.birthday = input
                  }}
                  {...textInputProps}
                />
              </div>
            </div>
          ) : null}
          {parseBoolean(enableFieldAdditionalInfo) && isRequest ? (
            <div>
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
              <div
                style={_.assign({}, styles.smallFormLine, styles.bottomBorderRadius, {
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <TextAreaInput
                  value={this.props.message}
                  placeholder={messagePlaceholder}
                  style={styles.singleFieldInput}
                  onChange={this.handleMessageChange}
                  {...textInputProps}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { date: selectedDate, eventId, inventoryItems: selectedInventoryItems } = state.userSelection.toJS()
  const { inventoryId } = selectedInventoryItems[0]
  const inventoryItem = state.entities.inventory.get(inventoryId)
  const event = state.entities.events.get(eventId).toJS()
  let firstNameLocked = false
  let lastNameLocked = false
  let emailLocked = false
  let phoneLocked = false
  if (state.clientInfo.lockedFields) {
    for (const attrib of Object.values(state.clientInfo.lockedFields)) {
      if (attrib === 'first_name') {
        firstNameLocked = true
      } else if (attrib === 'last_name') {
        lastNameLocked = true
      } else if (attrib === 'email') {
        emailLocked = true
      } else if (attrib === 'phone_number') {
        phoneLocked = true
      }
    }
  }
  return {
    firstNameLocked,
    lastNameLocked,
    emailLocked,
    phoneLocked,
    firstName: state.formFields.get('firstName'),
    lastName: state.formFields.get('lastName'),
    email: state.formFields.get('email'),
    phoneNumber: state.formFields.get('phoneNumber'),
    phoneNumberLocale: state.formFields.get('phoneNumberLocale'),
    message: state.formFields.get('message'),
    selectedDate,
    isRequest: inventoryItem.get('inventoryType') === 'REQUEST',
    widgetSettings: state.widgetSettings,
    time: state.formFields.get('time'),
    birthday: state.formFields.get('birthday'),
    partySize: state.formFields.get('partySize'),
    numMales: state.formFields.get('numMales'),
    numFemales: state.formFields.get('numFemales'),
    salutation: state.formFields.get('salutation'),
    mediaUrl: state.widgetSettings.mediaUrl,
    venueCountryCode: state.formFields.get('venueCountryCode'),
    birthdayType: state.widgetSettings.birthdayType,
    salutationType: state.widgetSettings.salutationType,
    eventStartDate: event.startDate,
    eventStartTime: event.eventStartTime,
    eventEndDate: event.endDate,
    eventEndTime: event.eventEndTime,
    enableFieldAdditionalInfo: state.widgetSettings.enableFieldAdditionalInfo,
    messagePlaceholder: parseBoolean(state.widgetSettings.enableFieldAdditionalInfo) ? 'additional information' : 'message',
    isMobile: state.app.isMobile,
    language: state.app.language,
    formErrors: state.formFields.get('formErrors').toJS(),
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    colorLines: state.widgetSettings.colorLines,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutBackground,
    hideCountryFlags: state.venueInfo.hideCountryFlags,
    venueLocale: state.venueInfo.municipality.locale,
    salutationOptions: state.venueInfo.salutationOptions,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorError: state.widgetSettings.fontsColorError,
    colorBackground: state.widgetSettings.colorBackground,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorPrimary: state.widgetSettings.colorPrimary,
    widgetTemplateTheme: state.widgetSettings.widgetTemplateTheme,
    colorCellBackground: state.widgetSettings.colorCellBackground,
  }
}

const mapDispatchToProps = dispatch => ({
  changePhoneNum: changeTo => {
    dispatch(changePhoneNum(changeTo))
  },
  changeFlag: (changeTo, selectedCountry) => {
    dispatch(changeFlag(changeTo, selectedCountry))
  },
  changeFormField: (field, e) => {
    if (typeof e === 'string') {
      dispatch(changeFormField(field, e))
    } else {
      dispatch(changeFormField(field, e.target.value))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutInformation)
