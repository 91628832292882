import { Map } from 'immutable'
import { GET_EVENTS_SUCCESS } from '../actions/ActionTypes'

export const RESERVATION_TYPE = 'RESERVATION'
export const GUESTLIST_TYPE = 'GUESTLIST_TICKET'
export const GUESTLIST_FREE_TYPE = 'GUESTLIST_TICKET_FREE'
export const REQUEST_TYPE = 'REQUEST'

const inventory = (state = Map(), action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return state.merge(action.data.inventory)
    default:
      return state
  }
}

export default inventory
