import React from 'react'
import { connect } from 'react-redux'
import BookingSuccess from '../containers/BookingSuccess'
import Checkout from '../containers/Checkout'
import ErrorPage from '../containers/ErrorPage'
import EventList from '../containers/EventList'
import EventOverview from '../containers/EventOverview'
import LoginPrompt from '../containers/LoginPrompt'
import ModalManager from '../containers/ModalManager'
import Wizard from './Wizard'

class Main extends React.PureComponent {
  constructor(props) {
    super(props)
    this.currentView = null
  }

  componentDidUpdate() {
    const renderView = this.viewSwitch()
    if (renderView !== this.currentView) {
      const wizTabElement = document.getElementById('wiz-first-tab')
      if (wizTabElement) {
        wizTabElement.focus()
      }
      this.currentView = renderView
    }
  }

  viewSwitch = (currentStage, isLoggedIn, queryObj, isFetchingEvents) => {
    switch (currentStage) {
      case 0:
        return <EventList currentStage={currentStage} />
      case 1:
        return <EventOverview currentStage={currentStage} isFetchingEvents={isFetchingEvents} />
      case 2: {
        const hasUserParams = !!queryObj.firstName || !!queryObj.lastName || !!queryObj.email || !!queryObj.phoneNumber
        return isLoggedIn || hasUserParams ? <Checkout currentStage={currentStage} /> : <LoginPrompt currentStage={currentStage} />
      }
      default:
        return <EventList currentStage={currentStage} />
    }
  }

  render() {
    const { currentStage, revertStage, isLoggedIn, queryObj, isFetchingEvents } = this.props
    return currentStage < 3 ? (
      <div id="app-main" style={{ boxSizing: 'border-box', height: '100%' }}>
        <ModalManager />
        {!this.props.isInteractiveFloorplanOpen && (
          <Wizard currentStage={currentStage} revertStage={revertStage} queryWidth={queryObj.width} />
        )}
        {this.viewSwitch(currentStage, isLoggedIn, queryObj, isFetchingEvents)}
      </div>
    ) : (
      <div id="app-main">{widgetInit.errorDisplay ? <ErrorPage /> : <BookingSuccess />}</div>
    )
  }
}

const mapStateToProps = state => ({
  isInteractiveFloorplanOpen: state.interactiveFloorplan.isOpen,
})

export default connect(mapStateToProps)(Main)
