import { scroller } from 'react-scroll'

const containerId = /MSIE \d|Trident.*rv:/.test(navigator.userAgent) ? null : 'bw-wrapper'

export default (targetId, offset, callback) => {
  scroller.scrollTo(targetId, {
    duration: 200,
    smooth: true,
    containerId,
    offset,
  })
  setTimeout(callback, 250)
}
