import { connect } from 'react-redux'
import { getSelectedInventoryItems } from '../selectors/inventoryCartSelector'

function PricingDetails({
  selectedInventoryItems,
  languageCode,
  currencyCode,
  promoCodeEntity,
  textServiceFee,
  onGratuityFieldChange,
  hasGratuityFieldError,
}) {
  const currencyFormatter = new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode })
  const { subTotal, additionalFeeAmount, additionalFeeTaxAmount, total } = selectedInventoryItems.reduce(
    (accum, { subTotal, additionalFeeAmount, additionalFeeTaxAmount, total }) => ({
      subTotal: accum.subTotal + parseFloat(subTotal),
      additionalFeeAmount: accum.additionalFeeAmount + additionalFeeAmount,
      additionalFeeTaxAmount: accum.additionalFeeTaxAmount + parseFloat(additionalFeeTaxAmount),
      total: accum.total + parseFloat(total),
    }),
    { subTotal: 0, additionalFeeAmount: 0, additionalFeeTaxAmount: 0, total: 0 }
  )
  const shouldDisplaySubtotal = true // TODO: need to figure this out
  const shouldChargeAdditionalFee = additionalFeeAmount > 0
  const adminFeeLabel = 'Admin Fee' // TODO: need to figure this out
  const shouldChargeAdditionalFeeTax = additionalFeeTaxAmount > 0
  const additionalFeeTaxPercent = 10 // TODO: need to figure this out

  return (
    <div style={{ fontSize: '14px', padding: '12px 4.1791% 16px 4.47761%' }}>
      {selectedInventoryItems.map(
        (
          {
            inventoryId,
            inventoryName,
            basePrice,
            discountedPrice,
            applyServiceCharge,
            serviceChargePercent,
            serviceCharge,
            chargeTax,
            tax,
            taxPercent,
            customTip,
            tip,
            tipPercent,
            chargeGratuity,
            chargeGratuityType,
          },
          index
        ) => (
          <div key={inventoryId} style={{ marginTop: index > 0 ? '8px' : '0' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{`Event (${inventoryName})`}</div>
              <div>{currencyFormatter.format(basePrice)}</div>
            </div>
            {promoCodeEntity && promoCodeEntity.promoComponent !== 'ALL' && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <div>{`Promo Code (${promoCodeEntity.code})`}</div>
                <div>{currencyFormatter.format(-discountedPrice)}</div>
              </div>
            )}
            {applyServiceCharge && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <div id="sr-service-charge-percent">{`${textServiceFee} (${serviceChargePercent}%)`}</div>
                <div id="sr-service-charge-price">{currencyFormatter.format(serviceCharge)}</div>
              </div>
            )}
            {chargeTax && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <div id="sr-tax-percent">{`Tax (${taxPercent}%)`}</div>
                <div id="sr-tax-price">{currencyFormatter.format(tax)}</div>
              </div>
            )}
            {chargeGratuity && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                {chargeGratuityType === 'CUSTOMER_VARIABLE' ? (
                  <div id="sr-gratuity-line">
                    <span>{'Tip '}</span>
                    <select
                      onChange={onGratuityFieldChange}
                      style={{
                        WebkitAppearance: 'inherit',
                        MozAppearance: 'none',
                        textIndent: 2,
                        width: 55,
                        height: 21,
                        textOverflow: '',
                        color: 'inherit',
                        borderColor: hasGratuityFieldError ? '#d01a21' : '#a6a6a6',
                        borderRadius: 'inherit',
                        backgroundColor: 'transparent',
                        backgroundImage:
                          'linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),linear-gradient(to right, #ccc, #ccc)',
                        backgroundPosition: 'calc(100% - 10px) calc(.6em), calc(100% - 5px) calc(.6em), calc(100% - 5px) 2em',
                        backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
                        backgroundRepeat: 'no-repeat',
                      }}
                      defaultValue={customTip}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {Array.from({ length: 41 }, (_, index) => index).map(val => (
                        <option key={val} value={val}>
                          {val}%
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div id="sr-tip-percent">{`Tip (${tipPercent}%)`}</div>
                )}
                <div id="sr-tip-price">{currencyFormatter.format(tip)}</div>
              </div>
            )}
            {promoCodeEntity && promoCodeEntity.promoComponent === 'ALL' && (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <div>{`Promo Code (${promoCodeEntity.code})`}</div>
                <div>{currencyFormatter.format(-discountedPrice)}</div>
              </div>
            )}
          </div>
        )
      )}
      {shouldDisplaySubtotal && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div>Order Subtotal</div>
          <div id="sr-price-subtotal">{currencyFormatter.format(subTotal)}</div>
        </div>
      )}
      {shouldChargeAdditionalFee && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-custom-fee-type">{`${adminFeeLabel || 'Admin Fee'}`}</div>
          <div id="sr-custom-fee-price">{currencyFormatter.format(additionalFeeAmount)}</div>
        </div>
      )}
      {shouldChargeAdditionalFeeTax && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-custom-fee-tax-type">{`${adminFeeLabel || 'Admin Fee'} Tax (${additionalFeeTaxPercent}%)`}</div>
          <div id="sr-custom-fee-tax-price">{currencyFormatter.format(additionalFeeTaxAmount)}</div>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', fontWeight: 500 }}>
        <div>Order Total</div>
        <div id="sr-price-total">{currencyFormatter.format(total)}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const selectedInventoryItems = getSelectedInventoryItems(state)
  return {
    languageCode: state.app.language,
    selectedInventoryItems,
    currencyCode: state.venueInfo.currencyCode,
    promoCodeEntity: state.entities.promoCode,
    textServiceFee: state.widgetSettings.textServiceFee,
  }
}
export default connect(mapStateToProps)(PricingDetails)
