import { Map } from 'immutable'
import { TOGGLE_IMAGE_GALLERY, STEP_FORWARD_IMAGE, STEP_BACK_IMAGE } from '../actions/ActionTypes'

const initialState = Map({
  displayImageGallery: false,
  imageIndex: 1,
})
const imageGallery = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_IMAGE_GALLERY:
      return state.update('displayImageGallery', flag => !flag)
    case STEP_FORWARD_IMAGE:
      if (state.get('imageIndex') === action.imgListLength) {
        return state
      }
      return state.update('imageIndex', idx => idx + 1)
    case STEP_BACK_IMAGE:
      if (state.get('imageIndex') === 1) {
        return state
      }
      return state.update('imageIndex', idx => idx - 1)
    default:
      return state
  }
}

export default imageGallery
