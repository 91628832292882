import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { changeFormField } from '../actions/forms'
import * as styles from '../assets/styles/checkout'
import { StripePaymentElementFields } from '@sevenrooms/payments'

function CheckoutPaymentStripe(props) {
  const {
    topElementId,
    fontFamily,
    fontsColorPrimary,
    fontsColorCheckoutInactive,
    fontsColorCheckoutActive,
    colorPrimary,
    colorCheckoutCellBackground,
    colorLines,
    stripeIntentClientSecret,
    locale,
  } = props

  return (
    <div id={topElementId} style={styles.sectionWrapper}>
      <div style={styles.labelWrapper}>
        <span
          style={_.assign({}, styles.sectionLabel, {
            color: fontsColorPrimary,
          })}
        >
          Payment
        </span>
      </div>
      <div style={styles.infoForm}>
        <div style={_.assign({}, styles.stripePaymentElement, { backgroundColor: colorCheckoutCellBackground })}>
          <StripePaymentElementFields
            stripe={props.stripe || null}
            clientSecret={stripeIntentClientSecret}
            locale={locale}
            fonts={`${props.baseUrl}${props.mediaUrl}css/fonts.css`}
            appearance={{
              variables: {
                fontFamily,
                fontSizeBase: styles.stripeIframe.fontSizeBase,
                fontSizeSm: styles.stripeIframe.fontSizeBase,
                fontSizeXs: styles.stripeIframe.fontSize,
                colorPrimary,
                colorTextPlaceholder: fontsColorCheckoutInactive,
                colorTextSecondary: fontsColorCheckoutActive,
                colorText: fontsColorPrimary,
                tabIconSelectedColor: fontsColorPrimary,
              },
              rules: {
                '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
                  backgroundColor: 'transparent',
                  boxShadow: `0 0 1px 1px ${colorLines}`,
                  border: `1px solid ${colorLines}`,
                },
                '.Tab--selected': {
                  border: `1px solid ${fontsColorPrimary}`,
                  boxShadow: `0 0 1px 1px ${colorLines}`,
                },
                '.TabLabel': {
                  fontSize: styles.stripeIframe.fontSizeBase,
                  fontWeight: 'normal',
                  color: fontsColorPrimary,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  fontFamily: state.widgetSettings.font,
  fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
  fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
  fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
  colorCheckoutCellBackground: state.widgetSettings.colorCheckoutBackground,
  colorPrimary: state.widgetSettings.colorPrimary,
  colorLines: state.widgetSettings.colorLines,
  baseUrl: state.widgetSettings.baseUrl,
  mediaUrl: state.widgetSettings.mediaUrl,
  locale: state.venueInfo.selectedLanguageCode,
})

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
})

// eslint-disable-next-line no-func-assign
CheckoutPaymentStripe = connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentStripe)

export default CheckoutPaymentStripe
