import { Map } from 'immutable'
import { GET_EVENTS_SUCCESS } from '../actions/ActionTypes'

const tieredEvents = (state = Map(), action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return state.merge(action.data.tieredEvents)
    default:
      return state
  }
}

export default tieredEvents
