import { Map } from 'immutable'
import _ from 'lodash'
import {
  SCROLL_BODY,
  TOP_BODY,
  BEGIN_SCROLL_ANIMATION,
  END_SCROLL_ANIMATION,
  GET_EVENTS_SUCCESS,
  HIGHLIGHT_DATE,
} from '../actions/ActionTypes'

const DEFAULT_EVENTS_DISPLAYED = 30
const LOAD_MORE_WITHIN_OFFSET = 100
const INCREMENTAL_EVENTS_DISPLAYED = 20
const initialState = Map({
  topClicked: false,
  offset: 0,
  bodyHeight: 0,
  listHeight: 0,
  numDatesDisplayed: 0,
  loadedDates: [],
  isAnimating: false,
})
const scroll = (state = initialState, action) => {
  switch (action.type) {
    case SCROLL_BODY: {
      const { offset, bodyHeight, listHeight } = action
      const incrementalDisplay = offset + bodyHeight + LOAD_MORE_WITHIN_OFFSET > listHeight ? INCREMENTAL_EVENTS_DISPLAYED : 0
      const numDatesDisplayed = Math.min(state.get('loadedDates').size, state.get('numDatesDisplayed') + incrementalDisplay)
      return state.merge({ offset, numDatesDisplayed, topClicked: false })
    }
    case GET_EVENTS_SUCCESS: {
      const loadedDates = _.sortBy(_.keys(action.data.availability))
      const numDatesDisplayed = Math.min(loadedDates.length, DEFAULT_EVENTS_DISPLAYED)
      return state.merge({ loadedDates, numDatesDisplayed })
    }
    case HIGHLIGHT_DATE: {
      const loadedDates = state.get('loadedDates')
      const dateIndex = loadedDates.indexOf(action.date)
      const numDatesDisplayed = Math.max(state.get('numDatesDisplayed'), Math.min(loadedDates.size, dateIndex + 10))
      return state.merge({ numDatesDisplayed })
    }
    case TOP_BODY:
      return state.set('topClicked', true)
    case BEGIN_SCROLL_ANIMATION:
      return state.set('isAnimating', true)
    case END_SCROLL_ANIMATION:
      return state.set('isAnimating', false)
    default:
      return state
  }
}

export default scroll
