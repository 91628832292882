/* eslint react/prop-types: 0, react/jsx-no-bind: 0 */
import _ from 'lodash'
import React from 'react'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import * as styles from '../assets/styles/wizard'
import { hexToRgb } from '../utils/color'

const { color_primary } = widgetInit.settings

const SVGPathComponent = props => <path {...props} />
const SVGPathComponentWithFocusContainer = accessibleComponentWrapper(SVGPathComponent)

const Wizard = ({ currentStage, revertStage, queryWidth }) => {
  const getIconStyle = index => {
    let iconColor = '#FFFFFF'
    if (color_primary === '#ffffff') {
      iconColor = '#000000'
    }
    const stylObj = _.assign({}, styles.icon)
    stylObj.color = currentStage >= index ? iconColor : hexToRgb(iconColor, 0.5)
    return stylObj
  }

  const getIconContainerStyle = index => {
    const stylObj = _.assign({}, styles.iconContainer)
    stylObj.left = `${(index / 3) * 100}%`
    return stylObj
  }

  const onStageClick = index => {
    if (currentStage > index) {
      revertStage(index)
    }
  }

  const getSvgStyle = index => {
    if (currentStage > index) {
      return _.assign({}, styles.tab, { cursor: 'pointer' })
    } else if (currentStage === index) {
      return styles.currentTab
    }
    return styles.tab
  }

  return (
    <div id="wiz-bar" style={queryWidth ? _.assign({}, styles.header, { width: `${queryWidth}px` }) : styles.header}>
      <svg style={styles.wizard} viewBox="0 0 375 64" preserveAspectRatio="none">
        <SVGPathComponentWithFocusContainer
          id="wiz-first-tab"
          onClick={onStageClick.bind(null, 0)}
          role="button"
          tabIndex="0"
          aria-label="Event Tab"
          style={getSvgStyle(0)}
          d="M 0,0 L 115,0 135,32 115,64 0,64 z"
        />
        <SVGPathComponentWithFocusContainer
          onClick={onStageClick.bind(null, 1)}
          role="button"
          tabIndex="0"
          aria-label="Inventory Tab"
          style={getSvgStyle(1)}
          d="M 115,0 L 240,0 260,32 240,64 115,64 135,32 z"
        />
        <SVGPathComponentWithFocusContainer
          onClick={onStageClick.bind(null, 2)}
          role="button"
          tabIndex="0"
          aria-label="Checkout Tab"
          style={getSvgStyle(2)}
          d="M 240,0 L 375,0 375,64 240,64 260,32 z"
        />
      </svg>
      <div style={getIconContainerStyle(0)}>
        <i style={getIconStyle(0)} className="fa fa-calendar-o fa-fw" aria-hidden="true" />
      </div>
      <div style={getIconContainerStyle(1)}>
        <i style={getIconStyle(1)} className="fa fa-file-text-o fa-fw" aria-hidden="true" />
      </div>
      <div style={getIconContainerStyle(2)}>
        <i style={getIconStyle(2)} className="fa fa-shopping-cart fa-fw" aria-hidden="true" />
      </div>
    </div>
  )
}

export default Wizard
