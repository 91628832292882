/* global widgetInit */
import { hexToRgb } from '../../utils/color'

const colorPrimary = widgetInit.settings.color_primary
const fontsColorPrimary = widgetInit.settings.fonts_color_primary
const { color_widget_background, color_widget_background_opacity } = widgetInit.settings
const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)

export const successWraper = {
  width: '100%',
  height: '100%',
  minHeight: '99.5vh',
  backgroundColor: rgbaWidgetBackground,
}

export const successHeader = {
  width: '100%',
  height: '154px',
  backgroundColor: colorPrimary,
}

export const official = {
  display: 'inline-block',
  margin: '22px auto 20px',
  width: '100%',
  height: '20px',
  fontFamily: 'inherit',
  fontSize: '16px',
  lineHeight: '1.2',
  letterSpacing: '1px',
  color: 'white',
  textAlign: 'center',
}

export const onTheBooks = {
  display: 'inline-block',
  width: '100%',
  height: '38px',
  fontFamily: 'inherit',
  fontSize: '28px',
  textAlign: 'center',
  color: 'white',
}

export const customerName = {
  display: 'inline-block',
  width: '100%',
  height: '38px',
  fontFamily: 'inherit',
  fontSize: '28px',
  textAlign: 'center',
  color: 'white',
}

export const successBody = {
  width: '100%',
  height: '406px',
  padding: '40px 0px 60px',
}

export const bodyText = {
  width: '100%',
  height: '38px',
  opacity: '0.7',
  fontFamily: 'inherit',
  fontSize: '14px',
  textAlign: 'center',
  color: fontsColorPrimary,
  marginBottom: '38px',
}

export const summaryDetails = {
  width: '100%',
  opacity: '0.7',
  fontFamily: 'inherit',
  fontSize: '14px',
  textAlign: 'center',
  color: fontsColorPrimary,
  marginBottom: '50px',
}

export const confirmation = {
  display: 'inline-block',
  width: '100%',
  textAlign: 'center',
}

export const email = {
  display: 'inline-block',
  width: '100%',
  textAlign: 'center',
}

export const shareBlock = {
  width: '100%',
  height: '65px',
  textAlign: 'center',
  marginBottom: '50px',
}

export const shareIcon = {
  width: '20.1px',
  height: '26.2px',
  color: fontsColorPrimary,
  fontSize: '27px',
  margin: '0 0 19px -2px',
}

export const shareText = {
  display: 'inline-block',
  width: '100%',
  height: '17px',
  fontFamily: 'inherit',
  fontSize: '14px',
  textAlign: 'center',
  color: fontsColorPrimary,
}

export const followUsBlock = {
  clear: 'both',
  marginBottom: '10px',
}

export const followText = {
  display: 'inline-block',
  width: '100%',
  height: '19px',
  opacity: '0.7',
  fontFamily: 'inherit',
  fontSize: '14px',
  textAlign: 'center',
  color: fontsColorPrimary,
  marginBottom: '6px',
}

export const iconBox = {
  width: '100%',
  height: '50px',
  color: fontsColorPrimary,
  opacity: '0.5',
  fontSize: '28px',
  textAlign: 'center',
  marginTop: '13px',
}

export const socialIcon = {
  display: 'inline-block',
  height: '50px',
  width: '50px',
  border: `2px solid ${fontsColorPrimary}`,
  borderRadius: '50%',
  position: 'relative',
  margin: '0 2.5%',
}

export const fontIcon = {
  color: fontsColorPrimary,
  display: '46px',
  lineHeight: '46px',
  height: '46px',
}

export const returnLink = {
  color: fontsColorPrimary,
  cursor: 'pointer',
  display: 'block',
  fontWeight: 'bold',
  padding: '20px',
}
