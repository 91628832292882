import _ from 'lodash'
import React, { Component } from 'react'
import ReactDom from 'react-dom'
import * as styles from '../assets/styles/checkout'
import { getDateTimeIntervals, getFormattedTime } from '../utils/date'
import placeholder from '../utils/reactInputPlaceholder'
import ItemPicker from './ItemPicker'
// placeholder polyfill
const { Input } = placeholder(React, ReactDom)

const internalStyles = {
  hiddenInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    fontSize: '20px',
    opacity: 0,
    zIndex: 1,
  },
}

class RequestField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
      isEmpty: true,
    }
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.customSettings =
      this.props.type === 'birthday'
        ? {
            placeholder: this.props.birthdayPlaceholder,
            type: 'text',
            width: '155px',
          }
        : {
            placeholder: 'Time *',
            type: 'time',
            width: '98px',
          }

    this.isTimeField = this.props.type === 'time'

    this.itemPickerColorProps = this.isTimeField
      ? {
          fontsColorPrimary: this.props.fontsColorPrimary,
          colorCellBackground: this.props.colorCellBackground,
          colorHover: this.props.colorHover,
          colorHighlightRow: this.props.colorHighlightRow,
          colorHighlightRowText: this.props.colorHighlightRowText,
        }
      : {}

    this.valueSet = this.isTimeField ? getDateTimeIntervals(this.props.eventStartTime, this.props.eventEndTime, this.props.language) : null
  }

  handleInputFocus() {
    this.setState({ isFocused: true })
    if (this.props.isMobile) {
      this.nativeTimeInput.focus()
    }
  }

  handleInputBlur() {
    this.setState({ isFocused: false })
  }

  handleInputChange(e) {
    if (typeof e === 'string') {
      this.handleInputBlur()
    } else if (!this.state.isEmpty && e.target.value === '') {
      this.setState({ isEmpty: true })
    } else if (this.state.isEmpty && e.target.value !== '') {
      this.setState({ isEmpty: false })
    }
    this.props.handleFieldChange(e)
  }

  render() {
    const { fieldIsInvalid } = this.props
    let inputComponent
    if (this.isTimeField || (this.state.isEmpty && !this.state.isFocused)) {
      inputComponent = (
        <Input
          type="text"
          className={fieldIsInvalid ? 'invalid-field' : ''}
          value={this.props.value}
          onFocus={this.handleInputFocus}
          placeholder={this.props.value ? '' : this.customSettings.placeholder}
          style={_.assign(
            {},
            styles.inputField,
            this.props.inputStyle,
            { width: this.customSettings.width },
            fieldIsInvalid ? styles.errorText : null
          )}
        />
      )
    } else {
      inputComponent = (
        <Input
          type={this.customSettings.type}
          className={fieldIsInvalid ? 'invalid-field' : ''}
          value={this.props.value}
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          placeholder={this.props.type === 'birthday' ? 'mm/dd' : this.customSettings.placeholder}
          style={
            fieldIsInvalid
              ? _.assign({}, styles.inputField, styles.errorText, {
                  width: this.customSettings.width,
                })
              : _.assign({}, styles.inputField, {
                  width: this.customSettings.width,
                })
          }
        />
      )
    }
    let picker
    if (this.props.isMobile) {
      picker = (
        <select
          style={internalStyles.hiddenInput}
          onChange={this.handleInputChange}
          value={this.props.value}
          ref={input => {
            this.nativeTimeInput = input
          }}
        >
          {_.map(this.valueSet, (val, idx) => (
            <option key={`sr-time-interval-${idx + 1}`} value={val}>
              {val}
            </option>
          ))}
        </select>
      )
    } else {
      picker = (
        <ItemPicker
          headerText="SELECT DESIRED TIME"
          selectedValue={this.props.value}
          valueSet={this.valueSet}
          onItemClick={this.handleInputChange}
          onOutsideClick={this.handleInputBlur}
          {...this.itemPickerColorProps}
        />
      )
    }
    return (
      <div style={this.props.divStyle}>
        {this.isTimeField && (this.props.isMobile || this.state.isFocused) && picker}
        {(!this.state.isFocused || this.props.isMobile || !this.isTimeField) && inputComponent}
      </div>
    )
  }
}

export default RequestField
