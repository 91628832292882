/* global document, parent, $ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { revertStage } from '../actions/navigation'
import * as styles from '../assets/styles/bookingSuccess'

class ErrorPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div style={styles.successWraper}>
        <div style={styles.successHeader}>
          <span style={styles.official} />
          <span style={styles.onTheBooks}>There was an error</span>
        </div>
        <div style={styles.successBody}>
          <div>
            <div style={styles.bodyText}>
              <span style={styles.confirmation}>We could not process your payment. Please try again later.</span>
              <span style={styles.returnLink} onClick={this.props.returnToList}>
                Return to event listing &#8634;
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  venueInfo: state.venueInfo,
})

const mapDispatchToProps = dispatch => ({
  returnToList: () => {
    dispatch(revertStage(0))
  },
})

ErrorPage = connect(mapStateToProps, mapDispatchToProps)(ErrorPage)

export default ErrorPage
