import { RESIZE_BODY } from '../actions/ActionTypes'

const getInitialState = () => {
  let width
  if (window.innerWidth > 700) {
    width = 700
  } else if (window.innerWidth < 300) {
    width = 300
  } else {
    width = window.innerWidth
  }
  return { width }
}
const resize = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESIZE_BODY:
      return { width: action.width, height: action.height }
    default:
      return state
  }
}

export default resize
