import { combineReducers } from 'redux'
import commonPaymentReducer from 'svr/component-lib/Widget/Payments/reducer'
import { interactiveFloorplan } from 'widget/events/InteractiveFloorplan/interactiveFloorplanReducer'
import availability from './availabilityReducer'
import calendar from './calendarReducer'
import events from './eventsReducer'
import featureFlags from './featureFlagsReducer'
import formFields from './formFieldsReducer'
import imageGallery from './imageGalleryReducer'
import inventoryCart from './inventoryCartReducer'
import inventory from './inventoryReducer'
import { isFetchingEvents, isFetchingLongtermEvents } from './isFetchingEventsReducer'
import paymentReducer from './paymentReducer'
import promoCode from './promoCodeReducer'
import resize from './resizeReducer'
import scroll from './scrollReducer'
import stage from './stageReducer'
import tags from './tagsReducer'
import tieredEvents from './tieredEventsReducer'
import unopenedEvents from './unopenedEventsReducer'
import user from './userReducer'
import userSelection from './userSelectionReducer'

// Entities stores state object models
const entities = combineReducers({
  promoCode,
  events,
  inventory,
  availability,
  unopenedEvents,
  tieredEvents,
  tags,
})

const nlBookingApp = combineReducers({
  venueInfo: (state = {}) => state,
  actualInfo: (state = {}) => state,
  clientInfo: (state = {}) => state,
  widgetSettings: (state = {}) => state,
  queryObj: (state = {}) => state,
  app: (state = {}) => state,
  languages: (state = {}) => state,
  theme: (state = {}) => state,
  entities,
  userSelection,
  interactiveFloorplan,
  isFetchingEvents,
  isFetchingLongtermEvents,
  stage,
  calendar,
  scroll,
  resize,
  imageGallery,
  user,
  formFields,
  payment: paymentReducer, // should be deleted later
  commonPayment: commonPaymentReducer,
  featureFlags,
  inventoryCart,
})

export default nlBookingApp
