import type { SvgInventoryByDate } from 'widget/events/InteractiveFloorplan/types'

export const SET_SVG_INVENTORY = 'SET_SVG_INVENTORY'
export const HANDLE_CLICK = 'HANDLE_CLICK'
export const HANDLE_HOVER = 'HANDLE_HOVER'
export const SET_INTERACTIVE_FLOORPLAN_HEIGHT = 'SET_INTERACTIVE_FLOORPLAN_HEIGHT'
export const IS_INTERACTIVE_FLOORPLAN_OPEN = 'IS_INTERACTIVE_FLOORPLAN_OPEN'

export const setSvgInventory = (svgInventory: SvgInventoryByDate) => ({
  type: SET_SVG_INVENTORY,
  svgInventory,
})

export interface HandleClickArgs {
  inventoryName: string
  shouldCenter: boolean
  selectedDate: string
}
export const handleClick = (handleClickArgs: HandleClickArgs) => ({ type: HANDLE_CLICK, handleClickArgs })

export interface HandleHoverArgs {
  inventoryName: string
  hover: boolean
  selectedDate: string
}
export const handleHover = (handleHoverArgs: HandleHoverArgs) => ({ type: HANDLE_HOVER, handleHoverArgs })

export interface SetFloorplanHeightArgs {
  height: number
}
export const setFloorplanHeight = ({ height }: SetFloorplanHeightArgs) => ({
  type: SET_INTERACTIVE_FLOORPLAN_HEIGHT,
  height,
})

export const setIsFloorplanOpen = (isOpen: boolean) => ({ type: IS_INTERACTIVE_FLOORPLAN_OPEN, isOpen })
