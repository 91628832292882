import { Facebook } from './FacebookAnalytics'
import { GoogleTags } from './GoogleTagManager'

class AnalyticsDistributor {
  constructor(trackingSystems) {
    this.trackingSystems = trackingSystems
  }

  isDoNotTrackEnabled() {
    const gpc = document.cookie.search('gpc=1;') !== -1
    return (
      gpc ||
      (navigator.globalPrivacyControl && navigator.globalPrivacyControl === '1') ||
      (window.doNotTrack && window.doNotTrack === '1') ||
      (navigator.doNotTrack && (navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1')) ||
      (navigator.msDoNotTrack && navigator.msDoNotTrack === '1') ||
      (window.external && 'msTrackingProtectionEnabled' in window.external && window.external.msTrackingProtectionEnabled())
    )
  }

  relayEvent(name, data) {
    if (this.isDoNotTrackEnabled()) {
      return
    }

    this.trackingSystems.forEach(system => {
      if (!(name in system)) {
        return
      }
      system[name](data)
    })
  }

  pageView(venueName) {
    this.relayEvent('pageView', { venueName })
  }

  selectDate(date) {
    this.relayEvent('selectDate', { date })
  }

  selectEvent(date, eventId, eventLabel) {
    this.relayEvent('selectEvent', { date, eventId, eventLabel })
  }

  selectInventory(inventoryId, availabilityId) {
    this.relayEvent('selectInventory', { inventoryId, availabilityId })
  }

  facebookLogin() {
    this.relayEvent('facebookLogin')
  }

  googleLogin() {
    this.relayEvent('googleLogin')
  }

  skipLogin() {
    this.relayEvent('skipLogin')
  }

  beginCheckout() {
    this.relayEvent('beginCheckout')
  }

  appliedPromoCode(code) {
    this.relayEvent('appliedPromoCode', { code })
  }

  failedCheckout(error) {
    this.relayEvent('failedCheckout', { error })
  }

  successfulCheckout(amount, currency) {
    this.relayEvent('successfulCheckout', { amount, currency })
  }
}

const Analytics = new AnalyticsDistributor([Facebook, GoogleTags])

export default Analytics
