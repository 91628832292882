/* eslint react/prop-types: 0, no-alert: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginUserFB, loginUserGP } from '../../../common/oauth'
import { skipLogin } from '../actions/navigation'
import Login from '../components/Login'
import Analytics from '../services/AnalyticsServices'

class LoginPrompt extends Component {
  constructor(props) {
    super(props)
    this.appWrapper = document.getElementById('bw-wrapper')
    this.appElement = document.getElementById('booking-widget-app')
  }

  componentDidMount() {
    this.appWrapper.scrollTop = 0

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(this.appElement.offsetHeight, '*')
  }

  render() {
    return <Login loginUserFB={this.props.loginUserFB} loginUserGP={this.props.loginUserGP} skipLogin={this.props.skipLogin} />
  }
}

const mapStateToProps = state => {
  const { inventoryItems } = state.userSelection.toJS()
  const { inventoryId } = inventoryItems[0]
  return {
    guestCheckout: state.entities.inventory.get(inventoryId).get('guestCheckout'),
  }
}

const mapDispatchToProps = dispatch => ({
  loginUserFB: () => {
    Analytics.facebookLogin()
    dispatch(loginUserFB())
  },
  loginUserGP: res => {
    Analytics.googleLogin()
    dispatch(loginUserGP(res, true))
  },
  skipLogin: () => {
    dispatch(skipLogin())
  },
})

LoginPrompt = connect(mapStateToProps, mapDispatchToProps)(Login)

export default LoginPrompt
