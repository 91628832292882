/* global widgetInit */
import _ from 'lodash'
import { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings } from 'widget/events/selectors/languageSelectors'
import { adaptPhotoData } from 'widget/events/utils/photoAdapters'
import {
  resizeBody,
  revertStage,
  selectInventoryItems,
  toggleImageGallery,
  toggleInventoryItem,
  toggleSpecialAttentionMessage,
} from '../actions/navigation'
import * as eventStyles from '../assets/styles/eventList'
import * as styles from '../assets/styles/eventOverview'
import EventDetails from '../components/EventDetails'
import InventoryList from '../components/InventoryList'
import Overview from '../components/Overview'
import PolicyPopover from '../components/PolicyPopover'
import EventInventoryList from '../components/InventoryList/InventoryList'
import { hexToRgb } from '../utils/color'
import ImageGallery from './ImageGallery'
import { InteractiveFloorplan } from 'widget/events/InteractiveFloorplan/InteractiveFloorplan'
import { handleClick, setIsFloorplanOpen } from 'widget/events/InteractiveFloorplan/actions'
import InteractiveFloorplanContainer from 'widget/events/InteractiveFloorplan/InteractiveFloorplanContainer'
import { parseBoolean } from 'widget/events/utils/preloadedState'

class EventOverview extends Component {
  // This is a workaround. From what I can tell, custom
  // link construction is a requested but not implemented
  // feature in DraftJS, and definitely isn't a configurable
  // option in react-rte, so solving it from the other side
  // until the libs catch up. -HW
  static _fixLinks() {
    const rt = document.getElementById('sr-rich-text-box')
    const links = rt?.getElementsByTagName('a') || []

    // Convert from HTMLCollection for map
    ;[].slice.call(links).map(link =>
      link.addEventListener('click', e => {
        e.preventDefault()
        window.open(link.href, '_blank')
        return false
      })
    )
  }

  constructor(props) {
    super(props)
    this.handleResizeDebounced = _.debounce(this.handleResize.bind(this), 250)
    this.appWrapper = document.getElementById('bw-wrapper')
    this.appElement = document.getElementById('booking-widget-app')
    this.state = { svg: '' }
  }

  componentWillMount() {
    const invKeys = _.keys(this.props.inventoryData)
    if (
      invKeys.length === 1 &&
      !this.props.inventoryEdits[invKeys[0]].isHighlighted &&
      this.props.inventoryData[invKeys[0]].inventoryType !== 'REQUEST'
    ) {
      this.props.boundToggleInventoryItem(invKeys[0])
    }
  }

  componentDidMount() {
    this.appWrapper.scrollTop = 0
    this.handleResizeDebounced()
    EventOverview._fixLinks()
    window.addEventListener('resize', this.handleResizeDebounced)

    if (this.props.interactiveFloorplanUrl) {
      fetch(this.props.interactiveFloorplanUrl).then(response => {
        response.text().then(svg => {
          this.setState({ svg })
          if (this.props.showInteractiveFloorplan) {
            this.props.toggleIsInteractiveFloorplanOpen(true)
          }
        })
      })
    }
  }

  componentWillUnmount() {
    if (this.props.showInteractiveFloorplan && this.state.svg) {
      this.props.toggleIsInteractiveFloorplanOpen(false)
    }
    window.removeEventListener('resize', this.handleResizeDebounced)
  }

  handleResize() {
    const appWidth = this.props.showInteractiveFloorplan ? this.appWrapper.offsetWidth : this.appElement.offsetWidth
    this.props.resizeBody(appWidth, window.innerHeight)
  }

  isEventOpened() {
    if (this.props.unopenedEvents.has(this.props.userSelection.eventId)) {
      const event = this.props.unopenedEvents.get(this.props.userSelection.eventId)
      if (event.has(this.props.userSelection.date)) {
        return false
      }
    }
    return true
  }

  getEventOpenDate() {
    if (this.props.unopenedEvents.has(this.props.userSelection.eventId)) {
      const event = this.props.unopenedEvents.get(this.props.userSelection.eventId)
      if (event.has(this.props.userSelection.date)) {
        const inventories = _.values(event.get(this.props.userSelection.date).toJS())
        let index = 0
        for (let i = 1; i < inventories.length; i += 1) {
          const earliestStartDate = new Date(inventories[index].start_time)
          const date = new Date(inventories[i].start_time)
          if (date < earliestStartDate) {
            index = i
          }
        }
        return inventories[index].start_time
      }
    }
    return ''
  }

  render() {
    const { color_widget_background, color_widget_background_opacity } = widgetInit.settings
    const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)
    const imageGallery = this.props.displayImageGallery && this.props.hasImages ? <ImageGallery /> : null
    const {
      colorPrimary,
      fontsColorButton,
      colorLines,
      showSpecialAttentionMessage,
      specialAttentionMessageHeader,
      specialAttentionMessageBody,
      displaySpecialAttentionMessage,
      toggleSpecialAttentionMessage,
      isFeesInPriceDisplayed,
    } = this.props

    if (this.props.showInteractiveFloorplan) {
      if (!this.state.svg) {
        return (
          <div style={eventStyles.loadingBackground}>
            <img alt="sevenrooms-spinner" style={eventStyles.loadingSpinner} src={`${this.props.mediaUrl}images/widget/sr_spinner.gif`} />
          </div>
        )
      }

      return (
        <InteractiveFloorplanContainer
          deviceSize={this.props.deviceSize}
          dismiss={this.props.revertStage(0)}
          eventData={this.props.eventData}
          backgroundColor={rgbaWidgetBackground}
          selectedDate={this.props.userSelection.date}
          textColor={this.props.fontsColorPrimary}
          venueInfo={this.props.venueInfo}
          eventDetailsComponent={
            <div style={{ backgroundColor: rgbaWidgetBackground }}>
              <EventDetails
                venueInfo={this.props.venueInfo}
                richText={this.props.richText}
                windowSize={0} // show details vertically
              />
            </div>
          }
          floorplanComponent={
            <InteractiveFloorplan
              availableInventory={Object.values(this.props.inventoryData)}
              soldInventory={this.props.soldInventoryData}
              boundToggleInventoryItem={this.props.boundToggleInventoryItem}
              selectedDate={this.props.userSelection.date}
              svg={this.state.svg}
              svgInventory={this.props.svgInventory}
              tieredEvents={this.props.tieredEvents}
            />
          }
          inventoryComponent={
            <InventoryList
              inventoryData={this.props.inventoryData}
              inventoryEdits={this.props.inventoryEdits}
              deviceSize="small" // floorplan always use InventoryItemSmall
              boundToggleInventoryItem={inventoryId => {
                this.props.boundToggleInventoryItem(inventoryId)
                this.props.handleClick(this.props.inventoryData[inventoryId].inventoryName, this.props.userSelection.date)
              }}
              selectInventoryItem={this.props.selectInventoryItem}
              isPrivate={this.props.isPrivate}
              isBookable={this.props.isBookable}
              isEventOpened={this.isEventOpened()}
              date={this.getEventOpenDate()}
              currencyCode={this.props.currencyCode}
              tieredEvents={this.props.tieredEvents}
              isExperienceMode={!!this.props.experienceId}
              isFloorplan
              isFeesInPriceDisplayed={isFeesInPriceDisplayed}
              defaultServiceCharge={this.props.venueInfo.venueDefaultServiceCharge}
            />
          }
        />
      )
    }

    return (
      <div style={styles.eventOverviewWrapper}>
        {displaySpecialAttentionMessage ? (
          <PolicyPopover
            policyHeader={specialAttentionMessageHeader}
            policyDetails={specialAttentionMessageBody}
            togglePopover={toggleSpecialAttentionMessage}
          />
        ) : null}
        {showSpecialAttentionMessage && (
          <div
            style={{
              backgroundColor: colorPrimary,
              fontColor: fontsColorButton,
              fontSize: '13px',
              borderBottomColor: colorLines,
              textAlign: 'center',
              paddingTop: '64px',
            }}
          >
            <div style={{ padding: '16px 0' }}>
              <span style={eventStyles.specialAttnLabel}>{specialAttentionMessageHeader}</span>
              <button
                type="button"
                style={{
                  color: fontsColorButton,
                  ...eventStyles.infoIcon,
                  ...eventStyles.buttonInfoIcon,
                }}
                onClick={toggleSpecialAttentionMessage}
                className="fa fa-info-circle fa-fw"
                aria-hidden="true"
                aria-label="special attention message"
              />
            </div>
          </div>
        )}
        {imageGallery}
        <table cellSpacing="0" cellPadding="0" style={styles.eventOverviewWrapper}>
          <tbody>
            <tr style={styles.tableRowHeight}>
              <td>
                <Overview
                  text={this.props.eventData.name}
                  banner={this.props.banner}
                  date={this.props.userSelection.date}
                  eventStartTime={this.props.eventData.eventStartTime}
                  endDate={this.props.eventData.endDate}
                  eventEndTime={this.props.eventData.eventEndTime}
                  toggleImageGallery={this.props.toggleImageGallery}
                  hasImages={this.props.hasImages}
                  mediaUrl={this.props.mediaUrl}
                />
              </td>
            </tr>
            {!this.props.isFetchingEvents ? (
              <tr style={styles.tableRowHeight}>
                <td>
                  {this.props.isMultiInventoryTypeEnabled ? (
                    <EventInventoryList
                      inventoryData={this.props.inventoryData}
                      inventoryEdits={this.props.inventoryEdits}
                      onContinue={this.props.selectInventoryItems}
                      isPrivate={this.props.isPrivate}
                      isBookable={this.props.isBookable}
                      isEventOpened={this.isEventOpened()}
                      date={this.getEventOpenDate()}
                      currencyCode={this.props.currencyCode}
                      tieredEvents={this.props.tieredEvents}
                      isExperienceMode={!!this.props.experienceId}
                    />
                  ) : (
                    <InventoryList
                      inventoryData={this.props.inventoryData}
                      inventoryEdits={this.props.inventoryEdits}
                      deviceSize={this.props.deviceSize}
                      boundToggleInventoryItem={this.props.boundToggleInventoryItem}
                      selectInventoryItem={this.props.selectInventoryItem}
                      isPrivate={this.props.isPrivate}
                      isBookable={this.props.isBookable}
                      isEventOpened={this.isEventOpened()}
                      date={this.getEventOpenDate()}
                      currencyCode={this.props.currencyCode}
                      tieredEvents={this.props.tieredEvents}
                      isExperienceMode={!!this.props.experienceId}
                      isFeesInPriceDisplayed={isFeesInPriceDisplayed}
                      defaultServiceCharge={this.props.venueInfo.venueDefaultServiceCharge}
                    />
                  )}
                </td>
              </tr>
            ) : null}
            <tr
              style={_.assign({}, styles.eventOverviewWrapper, {
                backgroundColor: rgbaWidgetBackground,
              })}
            >
              <td style={{ paddingBottom: '12px' }}>
                <EventDetails venueInfo={this.props.venueInfo} richText={this.props.richText} windowSize={this.props.windowSize} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
const mapStateToProps = state => {
  const deviceSize = state.resize.width > 640 ? 'large' : 'small'
  const userSelection = state.userSelection.toJS()

  let richText = ''
  let banner = null
  let eventData = {}
  const inventoryEdits = {}

  if (!state.isFetchingEvents) {
    eventData = state.entities.events.get(userSelection.eventId).toJS()

    richText = eventData.description
    banner = adaptPhotoData(eventData.photoMap[1], 'large', state.widgetSettings.baseUrl)
    const inventoryCart = state.inventoryCart.cart
    const availabilities = state.entities.availability.get(userSelection.date).get(userSelection.eventId).toJS()
    // eslint-disable-next-line guard-for-in
    for (const inventoryId in availabilities) {
      inventoryEdits[inventoryId] = {
        ...availabilities[inventoryId],
        quantity: inventoryCart.find(inventoryCartItem => inventoryCartItem.inventoryId === inventoryId).quantity,
      }
    }
  }

  const { unopenedEvents } = state.entities
  const tieredEvents = state.entities.tieredEvents.get(userSelection.eventId)?.get(userSelection.date)?.toJS() || null
  const inventoryData = _.reduce(
    inventoryEdits,
    (accum, item, inventoryId) => {
      const inventoryItem = state.entities.inventory.get(inventoryId).toJS()
      return item.remainingQuantity >= inventoryItem.minQuantity || inventoryItem.inventoryType === 'REQUEST'
        ? _.extend(accum, { [inventoryId]: inventoryItem })
        : accum
    },
    {}
  )
  const soldInventoryData = Object.entries(inventoryEdits)
    .filter(([inventoryId, item]) => {
      const inventoryItem = state.entities.inventory.get(inventoryId).toJS()
      return item.remainingQuantity < inventoryItem.minQuantity
    })
    .map(
      ([inventoryId, _]) => state.entities.inventory.get(inventoryId).toJS() // Get inventoryItem again
    )
  const { venueInfo } = state
  const displayImageGallery = state.imageGallery.get('displayImageGallery')
  const windowSize = state.resize.width
  const hasImages = _.some(eventData.photoMap, (imageData, key) => {
    if (key === '1') {
      return false
    }
    return !!imageData.photoDict
  })
  const { fontsColorPrimary, mediaUrl } = state.widgetSettings
  const isPrivate = eventData.privateEvent
  const isBookable = userSelection.eventId === state.queryObj.eventId
  const { currencyCode } = state.venueInfo
  const { experienceId } = state.queryObj
  const languageStrings = selectLanguageStrings(state)
  return {
    eventData,
    deviceSize,
    fontsColorPrimary,
    inventoryData,
    inventoryEdits,
    soldInventoryData,
    userSelection,
    venueInfo,
    richText,
    banner,
    displayImageGallery,
    windowSize,
    hasImages,
    mediaUrl,
    isPrivate,
    isBookable,
    unopenedEvents,
    currencyCode,
    tieredEvents,
    experienceId,
    colorPrimary: state.widgetSettings.colorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    colorLines: state.widgetSettings.colorLines,
    showSpecialAttentionMessage: parseBoolean(state.widgetSettings.showSpecialAttentionMessage),
    specialAttentionMessageHeader: languageStrings.textEventWidgetSpecialAttentionLabel,
    specialAttentionMessageBody: languageStrings.textEventWidgetSpecialAttentionInfoBody,
    displaySpecialAttentionMessage: state.formFields.get('displaySpecialAttentionMessage'),
    showInteractiveFloorplan: eventData.hasInteractiveFloorplan && !_.isEmpty(inventoryData),
    interactiveFloorplanUrl: eventData.interactiveFloorplanUrl,
    svgInventory: state.interactiveFloorplan.svgInventory,
    isFeesInPriceDisplayed: parseBoolean(state.widgetSettings.isFeesInPriceDisplayed),
    isMultiInventoryTypeEnabled: state.featureFlags.isMultiInventoryTypeEnabled,
  }
}

const mapDispatchToProps = dispatch => ({
  resizeBody: (width, height) => {
    dispatch(resizeBody(width, height))
  },
  revertStage: stage => () => dispatch(revertStage(stage)),
  toggleInventoryItem: (inventoryId, date, eventId) => {
    dispatch(toggleInventoryItem(inventoryId, date, eventId))
  },
  selectInventoryItem: (inventoryId, availabilityId) => {
    dispatch(selectInventoryItems([{ inventoryId, availabilityId }]))
  },
  selectInventoryItems: selectedInventoryItems => {
    dispatch(selectInventoryItems(selectedInventoryItems))
  },
  handleClick: (inventoryName, selectedDate) => dispatch(handleClick({ inventoryName, shouldCenter: true, selectedDate })),
  toggleImageGallery: () => {
    dispatch(toggleImageGallery())
  },
  toggleSpecialAttentionMessage: () => {
    dispatch(toggleSpecialAttentionMessage())
  },
  toggleIsInteractiveFloorplanOpen: isOpen => {
    dispatch(setIsFloorplanOpen(isOpen))
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const selectedDate = stateProps.userSelection.date
  const selectedEventId = stateProps.userSelection.eventId
  const boundToggleInventoryItem = dispatchProps.toggleInventoryItem.bind(null, selectedDate, selectedEventId)
  return _.assign({}, ownProps, stateProps, dispatchProps, {
    boundToggleInventoryItem,
  })
}

EventOverview = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventOverview)

export default EventOverview
