import _ from 'lodash'

export const camelCaseObject = item => {
  if (_.isPlainObject(item)) {
    const mappedKeys = _.mapKeys(item, (val, key) => _.camelCase(key))
    return _.mapValues(mappedKeys, camelCaseObject)
  }
  return item
}

export default camelCaseObject
