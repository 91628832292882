/* eslint import/no-extraneous-dependencies: 0, react/jsx-filename-extension: 0 */

import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import App from './containers/App'
import nlBookingApp from './reducers/combineReducers'
import preloadedState from './utils/preloadedState'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  nlBookingApp, // reducer
  preloadedState, // preloaded state
  composeEnhancers(
    // enhancers
    applyMiddleware(thunk)
  )
)

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <Component />
      </AppContainer>
    </Provider>,
    document.getElementById('booking-widget-app')
  )
}

render(App)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App)
  })
}
