interface CropData {
  x: number
  y: number
  width: number
  height: number
}

interface PhotoDict {
  large: string
  largeHeight: number
  largeWidth: number
  medium: string
  mediumHeight: number
  mediumWidth: number
  mega: string
  megaHeight: number
  megaWidth: number
  raw: string
  small: string
  smallHeight: number
  smallWidth: number
}

interface RawPhotoData {
  blobKey?: string
  cropData: CropData | null
  label: string
  photoDict?: PhotoDict
  url: string | null
}

type size = 'small' | 'medium' | 'large' | 'mega'

interface PhotoUrlData {
  cropData: CropData
  label: string
  src: string
  srcFallback: string
}

export const adaptPhotoData = (photoData: RawPhotoData | undefined, size: size, baseUrl: string): PhotoUrlData =>
  /**
   * This function mostly helps to add a fallback url for event photos
   *
   * The reason is sometimes when venues make many events with images,
   * the cropped image file is occasionally lost,
   * this fallback uses the original file and the cropped data to add a fallback
   */
  ({
    cropData: photoData?.cropData || { width: 100, height: 100, x: 0, y: 0 },
    label: photoData?.label || '',
    src: photoData?.photoDict ? `${baseUrl}/.h/download/${photoData.photoDict[size]}` : '',
    srcFallback: photoData?.url ? `${baseUrl}${photoData.url}` : '',
  })
