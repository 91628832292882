import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import styled from 'styled-components'
import { handleClick, handleHover, setFloorplanHeight, setSvgInventory } from './actions'
import { STYLES } from './styles'
import type { Inventory, SvgInventoryByDate } from './types'

interface InteractiveFloorplanProps {
  availableInventory: Inventory[]
  soldInventory: Inventory[]
  boundToggleInventoryItem: (inventoryId: string) => void
  selectedDate: string
  svg: string
  svgInventory: SvgInventoryByDate
  tieredEvents?: string[]
}

export const InteractiveFloorplan = ({
  availableInventory,
  soldInventory,
  boundToggleInventoryItem,
  selectedDate,
  svg,
  svgInventory,
  tieredEvents,
}: InteractiveFloorplanProps) => {
  const svgRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!svgRef.current) {
      return
    }
    const svgContainer = svgRef.current
    svgContainer.querySelector('title')?.remove() // svg edit adds placeholder title

    // by default set all the polygons to unavailable
    svgContainer.querySelectorAll('path').forEach(element => element.setAttribute('style', STYLES.unavailable))
    const initialInventory = availableInventory
      .filter(({ inventoryId }) => !tieredEvents?.includes(inventoryId))
      .map(({ inventoryId, inventoryName }) => {
        // we use the inventory names as area selectors (they map to the class names in the svg)
        const selectorClass = `.${CSS.escape(inventoryName.toLowerCase().replaceAll(' ', '-'))}` // Pool Area => .pool-area
        const elements = [...svgContainer.querySelectorAll(selectorClass)].map(element => {
          element.addEventListener('mouseover', () => dispatch(handleHover({ inventoryName, hover: true, selectedDate })))
          element.addEventListener('mouseout', () => dispatch(handleHover({ inventoryName, hover: false, selectedDate })))
          element.addEventListener('click', () => {
            boundToggleInventoryItem(inventoryId)
            dispatch(handleClick({ inventoryName, shouldCenter: false, selectedDate }))
          })
          return element
        })
        return {
          elements,
          inventoryId,
          inventoryName,
          status: svgInventory[selectedDate]?.find(({ inventoryName: svgInvName }) => svgInvName === inventoryName)?.status || 'default',
        } as const
      })
    const firstElement = initialInventory.find(({ elements }) => elements.length)?.elements[0]
    if (firstElement) {
      scrollIntoView(firstElement, { block: 'center', inline: 'center', behavior: 'smooth' })
    }

    dispatch(setFloorplanHeight({ height: svgContainer.offsetHeight }))
    dispatch(setSvgInventory({ [selectedDate]: initialInventory }))

    // careful to only set inventory state/handlers once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const svgInventoryBySelectedDate = svgInventory[selectedDate]
  useEffect(() => {
    soldInventory.forEach(({ inventoryName }) => {
      const selectorClass = `.${CSS.escape(inventoryName.toLowerCase().replaceAll(' ', '-'))}`
      svgRef.current?.querySelectorAll(selectorClass).forEach(element => element.setAttribute('style', STYLES.soldOut))
    })
    svgInventoryBySelectedDate?.forEach(({ elements, status }) => {
      switch (status) {
        case 'hover':
          elements.forEach(el => el.setAttribute('style', STYLES.hover))
          break
        case 'click':
          elements.forEach(el => el.setAttribute('style', STYLES.click))
          break
        default:
          elements.forEach(el => el.setAttribute('style', STYLES.available))
      }
    })
  }, [soldInventory, svgInventoryBySelectedDate])

  return <SvgContainer ref={svgRef} dangerouslySetInnerHTML={{ __html: svg }} />
}

const SvgContainer = styled.div`
  & > svg {
    display: block;
  }
`
