import { connect } from 'react-redux'
import { adaptPhotoData } from 'widget/events/utils/photoAdapters'
import { toggleImageGallery, stepForwardImage, stepBackImage } from '../actions/navigation'
import Gallery from '../components/Gallery'

const mapStateToProps = state => {
  const selectedEventId = state.userSelection.get('eventId')
  const eventData = state.entities.events.get(selectedEventId).toJS()
  const { baseUrl } = state.widgetSettings
  const imageData = Object.values(eventData.photoMap)
    .filter((item, idx) => item.url && idx > 0)
    .map(item => adaptPhotoData(item, 'large', baseUrl))
  const imageIndex = state.imageGallery.get('imageIndex')
  return { imageData, imageIndex }
}

const mapDispatchToProps = dispatch => ({
  toggleImageGallery: () => {
    dispatch(toggleImageGallery())
  },
  stepForwardImage: imgListLength => {
    dispatch(stepForwardImage(imgListLength))
  },
  stepBackImage: () => {
    dispatch(stepBackImage())
  },
})

const ImageGallery = connect(mapStateToProps, mapDispatchToProps)(Gallery)

export default ImageGallery
