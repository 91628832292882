const { color_primary, fonts_color_button } = widgetInit.settings

function ContinueButton({ children, disabled, onClick, ...props }) {
  return (
    <button
      type="button"
      style={{
        fontSize: '16px',
        fontWeight: 300,
        color: fonts_color_button,
        backgroundColor: color_primary,
        width: '130px',
        height: '40px',
        borderWidth: 0,
        borderRadius: '5px',
        opacity: disabled ? '0.3' : '1',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={e => {
        if (!disabled) {
          onClick(e)
        }
      }}
      aria-disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default ContinueButton
