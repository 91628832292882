import { Map } from 'immutable'
import { REVERT_STAGE, SELECT_EVENT, SELECT_INVENTORY_ITEM, POST_CHECKOUT_SUCCESS, SKIP_LOGIN } from '../actions/ActionTypes'

const stage = (state = Map(), action) => {
  switch (action.type) {
    case REVERT_STAGE:
      return state.merge({ current: action.index, isGuest: false })
    case SELECT_EVENT:
      return state.merge({ current: 1 })
    case SELECT_INVENTORY_ITEM:
      const skipLogin = action.hasClientToken || !action.isSocialMediaLoginEnabled
      return state.merge({ current: 2, isGuest: skipLogin })
    case POST_CHECKOUT_SUCCESS:
      return state.merge({ current: 3 })
    case SKIP_LOGIN:
      return state.set('isGuest', true)
    default:
      return state
  }
}

export default stage
