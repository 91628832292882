export const promoCodeTypes = {
  DOLLAR_DISCOUNT: 'DOLLAR_DISCOUNT',
  PERCENT_DISCOUNT: 'PERCENT_DISCOUNT',
  PERCENT_DISCOUNT_WITH_CAP: 'PERCENT_DISCOUNT_WITH_CAP',
}

export const promoCodeErrorTypes = {
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  EXHAUSTED: 'EXHAUSTED',
}

export const modalTypes = {
  DIET_TAG_SELECT: 'DIET_TAG_SELECT',
  SPECIAL_OCCASION_SELECT: 'SPECIAL_OCCASION_SELECT',
}
