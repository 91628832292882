/* eslint react/prop-types: 0, react/no-danger: 0 */
/* global widgetInit */
import React from 'react'
import * as styles from '../assets/styles/eventOverview'

const EventDetails = ({ venueInfo, richText, windowSize }) => {
  // TODO - switch this to use the methods in ../../../scripts/utils/googleMapLink.js
  const strNotNull = str => str || ''
  const venueCity = strNotNull(venueInfo.city)
  const venueState = strNotNull(venueInfo.state)
  const venuePostalCode = strNotNull(venueInfo.postalCode)
  const venueLocation = `${venueCity}${venueCity && venueState ? ', ' : ''}${venueState}${
    (venueCity || venueState) && venuePostalCode ? ' ' : ''
  }${venuePostalCode}`
  const venueAddress = strNotNull(venueInfo.address)

  const googleMapsURL = 'https://www.google.com/maps/'
  const googleDirectionsURL = `${googleMapsURL}dir//${venueAddress} ${venueLocation}`
  const googleEmbedURL = venueInfo.gmapsLink
    ? `${venueInfo.gmapsLink}&key=AIzaSyDP4KTsiA2CR5z0XU4KJizkccAhkrbg4UQ`
    : `${googleMapsURL}embed/v1/place?key=AIzaSyDP4KTsiA2CR5z0XU4KJizkccAhkrbg4UQ&q=${venueAddress} ${venueLocation}`

  return (
    <div style={styles.eventDetailsWrapper}>
      <style
        dangerouslySetInnerHTML={{
          __html: `#sr-rich-text-box a { color: ${widgetInit.settings.fonts_color_links};}`,
        }}
      />
      <div style={windowSize > 500 ? styles.bigLeftWrapper : styles.smallWrapper}>
        <div id="sr-rich-text-box" dangerouslySetInnerHTML={{ __html: richText }} />
      </div>
      <div style={windowSize > 500 ? styles.bigRightWrapper : styles.smallWrapper}>
        <div style={styles.venueDetailsWrapper}>
          VENUE DETAILS
          <ul style={styles.addressBox}>
            <li>{venueInfo.name}</li>
            <li>{venueAddress}</li>
            <li>{venueLocation}</li>
            <li>
              <a style={styles.aTag} target="_blank" rel="noreferrer noopener" href={googleDirectionsURL}>
                Get directions
              </a>
            </li>
          </ul>
        </div>
        {widgetInit.settings.displayMap === 'true' && (
          <div>
            <iframe title="event-map" style={styles.googleMapWrapper} src={googleEmbedURL} />
          </div>
        )}
      </div>
    </div>
  )
}

export default EventDetails
