import Immutable from 'immutable'
import { SAVE_TAG_GROUP } from '../actions/ActionTypes'

interface TagsAction {
  type: string
  groupId: string
  tagGroup: { [key: string]: string }
}

const tags = (state = Immutable.Map(), action: TagsAction) => {
  switch (action.type) {
    case SAVE_TAG_GROUP:
      return state.setIn(['tagGroups', action.groupId, 'selectedTags'], Immutable.Map(action.tagGroup))
    default:
      return state
  }
}

export default tags
