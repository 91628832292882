import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { scroller } from 'react-scroll'

const styles = {
  pickerWrapper: {
    position: 'relative',
    left: '-100%',
    width: '150%',
    top: '-110px',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '40px',
    zIndex: '10',
    boxShadow: '0px 0px 12px 3px rgba(0,0,0,0.26)',
  },
  headerText: {
    position: 'relative',
    maxWidth: '662px',
    width: '100%',
  },
  cellContainer: {
    position: 'relative',
    height: '300px',
    overflow: 'auto',
  },
  cellItem: {
    height: '40px',
    cursor: 'pointer',
  },
}

class ItemPicker extends Component {
  constructor(props) {
    super(props)

    const { valueSet, onItemClick } = this.props
    this.clickMap = _.reduce(
      valueSet,
      (map, value) => {
        const clickFunc = e => {
          e.stopPropagation()
          onItemClick(value)
        }
        return _.assign(map, { [value]: clickFunc })
      },
      {}
    )

    this.itemPickerId = 'sr-item-picker'

    this.clickPrevent = e => e.stopPropagation()
    this.allRows = _.fill(Array(3), '')
      .concat(valueSet)
      .concat(_.fill(Array(4), ''))
  }

  componentDidMount() {
    if (this.props.imgSrc) {
      scroller.scrollTo(`sr-${this.selectedValueNum - 3}`, {
        containerId: this.itemPickerId,
      })
    } else {
      scroller.scrollTo(`sr-${this.selectedValueNum - 1}`, {
        containerId: this.itemPickerId,
      })
    }
  }

  handleClickOutside() {
    this.props.onOutsideClick()
  }

  render() {
    const { headerText, style, fontsColorPrimary, colorCellBackground, colorHighlightRow, colorHighlightRowText, colorHover, imgSrc } =
      this.props

    const selectedValue = this.props.selectedValue || this.props.valueSet[0]

    const pickerCells = _.map(this.allRows, (value, idx) => {
      const isSelected = selectedValue === value
      if (isSelected) {
        this.selectedValueNum = idx
      }

      return (
        <div key={`sr-${idx}`} id={`sr-${idx}`}>
          <div
            key={`sr-radium-${idx}`}
            style={[
              styles.cellItem,
              isSelected && {
                color: colorHighlightRowText,
                backgroundColor: colorHighlightRow,
              },
              !isSelected && { backgroundColor: colorCellBackground },
              !isSelected && value && { ':hover': { backgroundColor: colorHover } },
            ]}
            onClick={this.clickMap[value]}
          >
            {value}
          </div>
        </div>
      )
    })

    const cellContainerCSS = imgSrc ? null : { height: '190px' }
    const pickerWrapperCSS = imgSrc ? null : { height: '230px', top: '-65px' }
    const cells = imgSrc ? pickerCells : pickerCells.splice(2)
    return (
      <div style={[styles.pickerWrapper, pickerWrapperCSS, { color: fontsColorPrimary, backgroundColor: colorCellBackground }, style]}>
        <div style={[styles.headerText, { backgroundColor: colorCellBackground }]}>{headerText}</div>
        <div id={this.itemPickerId} style={[styles.cellContainer, cellContainerCSS]}>
          {cells}
        </div>
      </div>
    )
  }
}

ItemPicker.propTypes = {
  headerText: React.PropTypes.string,
  selectedValue: React.PropTypes.node.isRequired,
  valueSet: React.PropTypes.arrayOf(React.PropTypes.node).isRequired,
  onItemClick: React.PropTypes.func.isRequired,
  onOutsideClick: React.PropTypes.func.isRequired,
  style: React.PropTypes.objectOf(React.PropTypes.string),
  fontsColorPrimary: React.PropTypes.string,
  colorCellBackground: React.PropTypes.string,
  colorHighlightRow: React.PropTypes.string,
  colorHighlightRowText: React.PropTypes.string,
  colorHover: React.PropTypes.string,
  imgSrc: React.PropTypes.string,
}

ItemPicker.defaultProps = {
  headerText: '',
  style: {},
  fontsColorPrimary: 'rgba(39, 39, 39, 1.00)',
  colorCellBackground: 'rgba(39, 39, 39, 1.00)',
  colorHighlightRow: 'rgba(39, 39, 39, 1.00)',
  colorHighlightRowText: 'rgba(39, 39, 39, 1.00)',
  colorHover: 'rgba(39, 39, 39, 1.00)',
}

export default onClickOutside(Radium(ItemPicker))
