/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
import _ from 'lodash'
import React from 'react'
import { AccessibleDivComponent } from 'svr/common/A11y'
import { AutoFallbackImage, CroppedImage } from '@sevenrooms/core/ui-kit/layout'
import * as styles from '../assets/styles/eventOverview'
import { getDayName, getMonthName, getFormattedTime } from '../utils/date'

const Overview = ({ text, date, eventStartTime, eventEndTime, banner, toggleImageGallery, hasImages, mediaUrl }) => {
  const dateArr = date.split('-')
  const [year, month, dayStr] = dateArr
  const day = dayStr[0] === '0' ? dayStr[1] : dayStr

  const dayName = getDayName('long', year, month - 1, day)
  const monthName = getMonthName(month - 1)

  const dot = eventStartTime || eventEndTime ? <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span> : null
  const dash = eventStartTime && eventEndTime ? <span>&nbsp;-&nbsp;</span> : null
  const startTime = eventStartTime ? getFormattedTime(eventStartTime) : null
  const endTime = eventEndTime ? getFormattedTime(eventEndTime) : null

  const heightFix = banner ? {} : { height: '80px' }
  let imageWrapper = _.assign({}, styles.imageWrapper, heightFix)
  imageWrapper = hasImages ? imageWrapper : _.assign({}, imageWrapper, { cursor: 'default ' })
  const galleryIcon = <img alt="gallery-icon" style={styles.galleryIcon} src={`${mediaUrl}images/widget/icon-photo-gallery.png`} />
  return (
    <AccessibleDivComponent
      style={styles.viewWrapper}
      onClick={hasImages ? toggleImageGallery : () => {}}
      aria-label="open event image gallery"
    >
      <div style={imageWrapper}>
        {hasImages ? galleryIcon : null}
        {banner && (
          <AutoFallbackImage
            fallback={<CroppedImage cropData={banner.cropData} style={styles.imageContent} src={banner.srcFallback} />}
            imageUrl={banner.src}
            style={styles.imageContent}
          />
        )}
        <div style={styles.gradient} />
        <div style={styles.overviewTextBox}>
          <div style={styles.label}>{text}</div>
          <div id="summaryDate" style={styles.datetime}>
            <span style={{ display: 'inline-block' }}>
              {`${dayName}, ${monthName} ${day}, ${year}`}
              {dot}
            </span>
            <span style={{ display: 'inline-block' }}>
              {startTime}
              {dash}
              {endTime}
            </span>
          </div>
        </div>
      </div>
    </AccessibleDivComponent>
  )
}

export default Overview
