/* eslint react/prop-types: 0,  jsx-a11y/no-static-element-interactions: 0 */
import React from 'react'
import { AccessibleDivComponent } from 'svr/common/A11y'
import { AutoFallbackImage, CroppedImage } from '@sevenrooms/core/ui-kit/layout'
import * as styles from '../assets/styles/eventOverview'

class Gallery extends React.PureComponent {
  constructor(props) {
    super(props)
    this.firstElementID = 'gallery-first-element-id'
    this.focusedElBeforeOpen = null
  }

  componentDidMount() {
    this.focusedElBeforeOpen = document.activeElement
    document.getElementById(this.firstElementID).focus()
  }

  _handleDismissModal = () => {
    this.focusedElBeforeOpen.focus()
    this.focusedElBeforeOpen = null
    this.props.toggleImageGallery()
  }

  render(props) {
    const { imageData, imageIndex, toggleImageGallery, stepForwardImage, stepBackImage } = this.props

    const { cropData, label, src, srcFallback } = imageData[imageIndex - 1]
    const clickPrevent = e => e.stopPropagation()

    const onClickStepForward = () => {
      stepForwardImage(imageData.length)
    }
    return (
      <AccessibleDivComponent
        style={styles.galleryCover}
        className="sr-gallery-cover"
        onClick={toggleImageGallery}
        aria-label="close event image gallery"
        focusOpacity={1}
      >
        <div role="alert" aria-label="Event Image Gallery" style={styles.hiddenAlert} />
        <div style={styles.imageStepper} onClick={clickPrevent}>
          <AccessibleDivComponent style={styles.imgStepperLeft} onClick={stepBackImage} aria-label="previous image">
            <i style={{ width: '8px' }} className="fa fa-angle-left" aria-hidden="true" />
          </AccessibleDivComponent>
          <span style={styles.stepDisplay}>{`${imageIndex}/${imageData.length}`}</span>
          <AccessibleDivComponent
            id={this.firstElementID}
            style={styles.imgStepperRight}
            onClick={onClickStepForward}
            aria-label="next image"
          >
            <i style={{ width: '8px' }} className="fa fa-angle-right" aria-hidden="true" />
          </AccessibleDivComponent>
        </div>
        <div style={styles.largeImageWrapper} onClick={clickPrevent}>
          <div style={styles.largeImageBody}>
            <AutoFallbackImage
              imageUrl={src}
              style={styles.largeImageContent}
              fallback={<CroppedImage src={srcFallback} cropData={cropData} style={styles.largeImageContent} />}
            />
            <div style={styles.imgLabel}>{label}</div>
            <AccessibleDivComponent style={styles.imageX} onClick={this._handleDismissModal} aria-label="close image gallery">
              ×
            </AccessibleDivComponent>
          </div>
        </div>
      </AccessibleDivComponent>
    )
  }
}

export default Gallery
