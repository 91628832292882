export const GoogleTags = {
  execute(eventName, data) {
    if (!window.embeddedGtag) {
      return
    }
    window.embeddedGtag({ event: eventName, ...data })
  },

  pageView(data) {
    this.execute('pageView', data)
  },

  selectDate(data) {
    this.execute('selectDate', data)
  },

  selectEvent(data) {
    this.execute('selectEvent', data)
  },

  selectInventory(data) {
    this.execute('selectInventory', data)
  },

  facebookLogin() {
    this.execute('login', { type: 'facebook' })
  },

  googleLogin() {
    this.execute('login', { type: 'google' })
  },

  skipLogin() {
    this.execute('skipLogin')
  },

  beginCheckout() {
    this.execute('beginCheckout')
  },

  appliedPromoCode(data) {
    this.execute('appliedPromoCode', data)
  },

  failedCheckout(data) {
    this.execute('failedCheckout', data)
  },

  successfulCheckout(data) {
    this.execute('successfulCheckout', data)
  },
}
