import { scrollIntoView } from 'seamless-scroll-polyfill'
import {
  HANDLE_CLICK,
  HANDLE_HOVER,
  type HandleClickArgs,
  type HandleHoverArgs,
  SET_SVG_INVENTORY,
  SET_INTERACTIVE_FLOORPLAN_HEIGHT,
  IS_INTERACTIVE_FLOORPLAN_OPEN,
} from './actions'
import type { SvgInventoryByDate } from './types'

interface InteractiveFloorPlanAction {
  svgInventory: SvgInventoryByDate
  handleClickArgs: HandleClickArgs
  handleHoverArgs: HandleHoverArgs
  isOpen: boolean
  height?: number
  type: string
}

export interface FloorPlanState {
  svgInventory: SvgInventoryByDate
  isOpen: boolean
  height?: number
}

const initialState = {
  svgInventory: {},
  isOpen: false,
}

export const interactiveFloorplan = (state: FloorPlanState = initialState, action: InteractiveFloorPlanAction): FloorPlanState => {
  switch (action.type) {
    case SET_SVG_INVENTORY:
      return { ...state, svgInventory: { ...state.svgInventory, ...action.svgInventory } }
    // handlers use inventory name over id because we need to update a section of items
    case HANDLE_CLICK:
      return {
        ...state,
        svgInventory: {
          [action.handleClickArgs.selectedDate]:
            state.svgInventory[action.handleClickArgs.selectedDate]?.map(inventory => {
              if (inventory.inventoryName === action.handleClickArgs.inventoryName) {
                // already clicked, toggle off
                if (inventory.status === 'click') {
                  return { ...inventory, status: 'default' }
                }
                if (action.handleClickArgs.shouldCenter && inventory.elements[0]) {
                  scrollIntoView(inventory.elements[0], { block: 'center', inline: 'center', behavior: 'smooth' })
                }
                return { ...inventory, status: 'click' }
              }
              return { ...inventory, status: 'default' }
            }) || [],
        },
      }
    case HANDLE_HOVER:
      return {
        ...state,
        svgInventory: {
          [action.handleHoverArgs.selectedDate]:
            state.svgInventory[action.handleHoverArgs.selectedDate]?.map(inventory => {
              if (inventory.status === 'click') {
                return inventory
              } else if (action.handleHoverArgs.hover && inventory.inventoryName === action.handleHoverArgs.inventoryName) {
                return { ...inventory, status: 'hover' }
              }
              return { ...inventory, status: 'default' }
            }) || [],
        },
      }
    case SET_INTERACTIVE_FLOORPLAN_HEIGHT:
      return {
        ...state,
        height: action.height,
      }
    case IS_INTERACTIVE_FLOORPLAN_OPEN:
      return {
        ...state,
        isOpen: action.isOpen,
      }
    default:
      return state
  }
}
