/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
import React from 'react'
import { accessibleComponentWrapper, AccessibleDivComponent } from 'svr/common/A11y'
import * as styles from '../assets/styles/checkout'

const SpanComponent = props => <span role="button" tabIndex="0" {...props} />
const AccessibleSpanComponent = accessibleComponentWrapper(SpanComponent, false)

class PolicyPopover extends React.PureComponent {
  constructor(props) {
    super(props)
    this.firstElementID = 'policy-first-element-id'
    this.focusedElBeforeOpen = null
  }

  componentDidMount() {
    this.focusedElBeforeOpen = document.activeElement
    document.getElementById(this.firstElementID).focus()
  }

  _handleDismissModal = () => {
    this.focusedElBeforeOpen.focus()
    this.focusedElBeforeOpen = null
    this.props.togglePopover()
  }

  _clickPrevent = e => e.stopPropagation()

  render() {
    return (
      <AccessibleDivComponent style={styles.policyCover} className="sr-gallery-cover" onClick={this._handleDismissModal} focusOpacity={1}>
        <div style={styles.policyWrapper} onClick={this._clickPrevent}>
          <div role="alert" style={styles.hiddenAlert} aria-label={this.props.policyHeader} />
          <div style={styles.policyHeader}>
            <span style={styles.headerText}>{this.props.policyHeader}</span>
            <AccessibleSpanComponent id={this.firstElementID} style={styles.headerX} onClick={this._handleDismissModal} focusOpacity={1}>
              ×
            </AccessibleSpanComponent>
          </div>
          <div style={styles.policyBody} dangerouslySetInnerHTML={{ __html: this.props.policyDetails }} />
        </div>
      </AccessibleDivComponent>
    )
  }
}

export default PolicyPopover
