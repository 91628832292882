export const Facebook = {
  execute(...args) {
    if (!window.embeddedFbq) {
      return
    }
    window.embeddedFbq.apply(null, args)
  },

  pageView() {
    this.execute('track', 'PageView')
  },

  beginCheckout() {
    this.execute('track', 'InitiateCheckout')
  },

  successfulCheckout(data) {
    this.execute('track', 'Purchase', { currency: data.currency, value: data.amount })
  },

  selectDate(data) {
    this.execute('track', 'Schedule', { date: data.date })
  },
}
