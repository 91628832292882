/* global widgetInit */
import { hexToRgb } from '../../utils/color'

const fontsColorPrimary = widgetInit.settings.fonts_color_primary
const fontsColorSecondary = widgetInit.settings.fonts_color_secondary
const widgetBackground = hexToRgb(widgetInit.settings.color_widget_background, widgetInit.settings.color_widget_background_opacity)
const notAvailFontColor = hexToRgb(fontsColorPrimary, 0.5)

export const calendarBackground = {
  fontFamily: 'inherit',
  backgroundColor: widgetBackground,
}

export const calNav = {
  weight: '100%',
  textAlign: 'center',
  color: fontsColorPrimary,
  padding: '20px 0',
  height: '67px',
  lineHeight: '27px',
}

export const monthLabel = {
  display: 'inline-block',
  fontSize: '18px',
  height: '27px',
  overflow: 'auto',
}

export const leftArrow = {
  float: 'left',
  height: '27px',
  padding: '0 3.2%',
  fontSize: '23px',
  cursor: 'pointer',
  outline: 'none',
}

export const rightArrow = {
  float: 'right',
  height: '27px',
  padding: '0 3.2%',
  fontSize: '23px',
  cursor: 'pointer',
  outline: 'none',
}

export const headerStyle = {
  width: '100%',
  height: '30px',
  padding: '8px 2.68717%',
}

export const dayLabel = {
  display: 'block',
  float: 'left',
  width: '11.58299%',
  height: '14px',
  lineHeight: '14px',
  fontSize: '10px',
  letterSpacing: '1px',
  textAlign: 'center',
  color: fontsColorSecondary,
  margin: '0 1.31393%',
}

export const calendarPad = {
  padding: '0 2.68717%',
}

export const calendarWrapper = {
  position: 'relative',
  height: '0',
  paddingBottom: '83.2%',
  fontFamily: 'inherit',
  fontWeight: '300',
}

export const calendarBody = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}

export const cellStyle = {
  display: 'block',
  float: 'left',
  width: '11.58299%',
  height: '16.5%',
  fontSize: '14px',
  textAlign: 'center',
  margin: '0 1.31393%',
  position: 'relative',
  outline: 'none',
}

export const calNumAvail = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  margin: '-0.6em 0 0 -25%',
  zIndex: '8',
  color: fontsColorPrimary,
}

export const calNumNotAvail = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  margin: '-0.70em 0 0 -25%',
  zIndex: '8',
  color: notAvailFontColor,
}

export const currentCircle = {
  position: 'absolute',
  borderRadius: '50%',
  width: '68%',
  paddingBottom: '68%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: `2px solid ${fontsColorPrimary}`,
  boxSizing: 'content-box',
  zIndex: '10',
}
