/* global widgetInit */
import { hexToRgb } from '../../utils/color'

const fontsColorPrimary = widgetInit.settings.fonts_color_primary
// eslint-disable-next-line camelcase
const { color_widget_background, color_widget_background_opacity } = widgetInit.settings
const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)

export const viewWrapper = {
  height: '100%',
  minHeight: '99.5vh',
  paddingTop: '64px',
  fontFamily: 'inherit',
  backgroundColor: rgbaWidgetBackground,
}

export const banner = {
  width: '100%',
  height: '30px',
  backgroundColor: fontsColorPrimary,
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: '500',
  opacity: '0.9',
  color: 'white',
  paddingTop: '7px',
}

export const leadingText = {
  width: '304px',
  height: '38px',
  opacity: '0.7',
  fontWeight: '300',
  fontSize: '14px',
  textAlign: 'center',
  color: fontsColorPrimary,
  margin: '17px auto 24px',
}

export const fbButton = {
  width: '90%',
  maxWidth: '330px',
  height: '40px',
  backgroundColor: 'rgb(60, 114, 234)',
  margin: '10px auto',
  cursor: 'pointer',
  borderRadius: '5px',
}

export const fbBox = {
  width: '40px',
  height: '40px',
  lineHeight: '36px',
  float: 'left',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const fbIcon = {
  height: '26px',
  verticalAlign: 'middle',
}

export const fbText = {
  fontFamily: 'Roboto',
  width: '82.70948%',
  height: '40px',
  lineHeight: '40px',
  fontSize: '14px',
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#fdfeff',
  display: 'block',
  fontWeight: '400',
  float: 'left',
}

export const liButton = {
  width: '90%',
  maxWidth: '330px',
  height: '44px',
  backgroundColor: '#4a90e2',
  margin: '10px auto',
  cursor: 'pointer',
}

export const liBox = {
  width: '44px',
  height: '44px',
  opacity: '0.84',
  backgroundColor: '#407fc9',
  display: 'block',
  float: 'left',
  textAlign: 'center',
}

export const liIcon = {
  color: 'white',
  fontSize: '16px',
}

export const liText = {
  width: '82.70948%',
  height: '44px',
  lineHeight: '44px',
  fontSize: '11px',
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#fdfeff',
  display: 'block',
  fontWeight: '500',
  float: 'left',
  marginTop: '2px',
}

export const gpButton = {
  width: '90%',
  maxWidth: '330px',
  height: '44px',
  margin: '10px auto',
  cursor: 'pointer',
}

export const gpBox = {
  width: '44px',
  height: '44px',
  opacity: '0.84',
  backgroundColor: '#d82803',
  float: 'left',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const gpIcon = {
  color: 'white',
  fontSize: '16px',
}

export const gpText = {
  width: '82.70948%',
  height: '44px',
  lineHeight: '44px',
  fontSize: '11px',
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#fdfeff',
  display: 'block',
  fontWeight: '500',
  float: 'left',
  marginTop: '2px',
}

export const loginSeperator = {
  position: 'relative',
  margin: '20px auto',
  width: '80%',
  maxWidth: '300px',
}

export const lineBreak = {
  borderTop: `2px solid ${fontsColorPrimary}`,
  width: '37.5%',
  display: 'inline-block',
  padding: '2px',
}

export const lineBreakText = {
  fontFamily: 'inherit',
  fontSize: '12px',
  fontWeight: '500',
  display: 'inline-block',
  margin: '5px 6.239%',
  color: fontsColorPrimary,
  width: '8%',
  textAlign: 'center',
}

export const guestButton = {
  textAlign: 'center',
}

export const guestText = {
  height: '22px',
  fontFamily: 'inherit',
  fontSize: '16px',
  lineHeight: '23px',
  letterSpacing: '1px',
  borderBottomWidth: '2px',
  borderBottomStyle: 'solid',
  cursor: 'pointer',
  color: fontsColorPrimary,
}
