export const colorLuminance = (hexVal, lumVal) => {
  if (hexVal === '#000000') {
    return '#4c4c4c'
  } else if (hexVal === '#ffffff') {
    return '#e5e5e5'
  }
  let hex = hexVal
  let lum = lumVal
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0
  let rgb = '#'
  let c
  let i
  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += `00${c}`.substr(c.length)
  }
  return rgb
}

export const hexToRgb = (hex, alpha) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const resultAlpha = alpha === undefined ? 1 : alpha
  if (result) {
    const rgbArr = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    return `rgba(${rgbArr.join(', ')}, ${resultAlpha})`
  }
  return null
}
