/* eslint react/prop-types: 0 jsx-a11y/no-static-element-interactions: 0 */
import _ from 'lodash'
import React from 'react'
import { AccessibleDivComponent } from 'svr/common/A11y'
import { calNav, monthLabel, leftArrow, rightArrow } from '../assets/styles/calendar'
import { getMonthName } from '../utils/date'

const CalendarNav = ({ calendarInfo, stepForwardMonth, stepBackMonth }) => {
  const { calendarMonth, calendarYear, currentMonth, currentYear } = calendarInfo
  const monthName = getMonthName(calendarMonth)

  const isLeftArrowActive = () => !(currentYear === calendarYear && currentMonth === calendarMonth)

  const leftArrowElement = isLeftArrowActive() ? (
    <AccessibleDivComponent aria-label="decrement month" style={leftArrow} onClick={stepBackMonth}>
      <i style={{ width: '8px' }} className="fa fa-angle-left" aria-hidden="true" />
    </AccessibleDivComponent>
  ) : (
    <div style={_.assign({}, leftArrow, { opacity: '0.3', cursor: 'default' })}>
      <i style={{ width: '8px' }} className="fa fa-angle-left" aria-hidden="true" />
    </div>
  )

  return (
    <div style={calNav}>
      {leftArrowElement}
      <div role="alert" style={monthLabel}>{`${monthName} ${calendarYear}`}</div>
      <AccessibleDivComponent aria-label="increment month" style={rightArrow} onClick={stepForwardMonth}>
        <i style={{ width: '8px' }} className="fa fa-angle-right" aria-hidden="true" />
      </AccessibleDivComponent>
    </div>
  )
}

export default CalendarNav
