import _ from 'lodash'
import { connect } from 'react-redux'
import { incrementItem, decrementItem } from '../actions/navigation'
import Stepper from '../components/Stepper'

const mapStateToProps = state => ({
  inventory: state.entities.inventory.toJS(),
  selectedDate: state.userSelection.get('date'),
  selectedEvent: state.userSelection.get('eventId'),
})

const mapDispatchToProps = dispatch => ({
  incrementItem: (inventoryId, date, eventId) => {
    dispatch(incrementItem(inventoryId, date, eventId))
  },
  decrementItem: (inventoryId, date, eventId) => {
    dispatch(decrementItem(inventoryId, date, eventId))
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const boundIncrementItem = dispatchProps.incrementItem.bind(null, stateProps.selectedDate, stateProps.selectedEvent)
  const boundDecrementItem = dispatchProps.decrementItem.bind(null, stateProps.selectedDate, stateProps.selectedEvent)
  const { inventory } = stateProps
  return _.assign({}, ownProps, {
    inventory,
    boundIncrementItem,
    boundDecrementItem,
  })
}

const QuantityStepper = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Stepper)

export default QuantityStepper
