/* global document, parent, $ */
import priceFormatter from 'currency-formatter'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as styles from '../assets/styles/bookingSuccess'
import { getDateFormatMedium, getFormattedTime } from '../utils/date'

class BookingSuccess extends Component {
  constructor(props) {
    super(props)
    this.appWrapper = document.getElementById('bw-wrapper')
  }

  componentDidMount() {
    this.appWrapper.scrollTop = 0
  }

  getHeader() {
    if (this.props.inventoryType === 'REQUEST') {
      return (
        <div style={styles.successHeader}>
          <span style={styles.official}>Done!</span>
          <span style={styles.onTheBooks}>Your request has been submitted</span>
        </div>
      )
    }
    return (
      <div style={styles.successHeader}>
        <span style={styles.official}>Thank you.</span>
        <span style={styles.onTheBooks}>We look forward to welcoming you.</span>
      </div>
    )
  }

  render() {
    const minDollarText = (function (minPrice, quantity, currencyCode, textMinimumSpend) {
      if (!minPrice) {
        return ''
      }
      const minDollarAmount = quantity * minPrice
      const minDollarAmountFormatted = priceFormatter.format(minDollarAmount, {
        code: currencyCode,
      })
      return `, ${minDollarAmountFormatted} ${textMinimumSpend}`
    })(this.props.minPrice, this.props.quantity, this.props.currencyCode, this.props.textMinimumSpend)

    const guests = `${this.props.maxGuests} guest${this.props.maxGuests === 1 ? '' : 's'}`
    const guestsLiner = this.props.isGuestlist ? guests + minDollarText : `Up to ${guests}${minDollarText}`
    const { facebookLink, twitterLink, snapchatLink, instagramLink } = this.props.venueInfo

    try {
      window.eval(widgetInit.customSuccess)
    } catch (e) {}

    return (
      <div style={styles.successWraper}>
        {this.getHeader()}
        <div style={styles.successBody}>
          {this.props.inventoryType === 'REQUEST' ? (
            <div style={styles.bodyText}>
              <span style={styles.confirmation}>someone will be in touch shortly at</span>
              <span style={styles.email}>{this.props.email}</span>
            </div>
          ) : (
            <div>
              <div style={styles.bodyText}>
                <span style={styles.confirmation}>Your confirmation and details have been sent to</span>
                <span style={styles.email}>{this.props.email}</span>
              </div>
              <div style={styles.summaryDetails}>
                <span style={styles.confirmation}>
                  {this.props.firstName} {this.props.lastName}
                </span>
                <span style={styles.confirmation}>
                  {this.props.venueInfo.name} - {this.props.eventName}
                </span>
                <span style={styles.confirmation}>
                  {this.props.date} &#x2022; {this.props.eventTime}
                </span>
                <span style={styles.confirmation}>{guestsLiner}</span>
                <span style={styles.confirmation}>
                  ({this.props.quantity}) {this.props.inventoryName}
                </span>
              </div>
            </div>
          )}
          <div>
            {facebookLink || instagramLink || twitterLink || instagramLink ? <span style={styles.followText}>follow us on</span> : null}
            <div style={styles.iconBox}>
              {facebookLink ? (
                <a target="_blank" rel="noopener noreferrer" href={facebookLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-facebook" />
                  </div>
                </a>
              ) : null}
              {instagramLink ? (
                <a target="_blank" rel="noopener noreferrer" href={instagramLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-social-instagram" />
                  </div>
                </a>
              ) : null}
              {twitterLink ? (
                <a target="_blank" rel="noopener noreferrer" href={twitterLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-twitter" />
                  </div>
                </a>
              ) : null}
              {snapchatLink ? (
                <a target="_blank" rel="noopener noreferrer" href={snapchatLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-social-snapchat" />
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const dataFromSnapshot = (actualInfo, venueInfo) => {
  const { availability, firstName, lastName, email, partySize, eventName, date, time } = actualInfo

  return {
    email,
    firstName,
    lastName,
    date: getDateFormatMedium(date),
    eventName,
    eventTime: getFormattedTime(time),
    inventoryName: availability.inventory_name,
    inventoryType: availability.inventory_type,
    isGuestlist: ['GUESTLIST_TICKET', 'GUESTLIST_TICKET_FREE'].includes(availability.inventory_type),
    quantity: availability.quantity,
    maxGuests: availability.quantity * availability.entry_per_reservation,
    venueInfo,
  }
}

const mapStateToProps = state => {
  if (widgetInit.successBypass) {
    return dataFromSnapshot(state.actualInfo, state.venueInfo)
  }

  const { date, eventId, inventoryItems: selectedInventoryItems } = state.userSelection.toJS()
  const event = state.entities.events.get(eventId)
  const { inventoryId } = selectedInventoryItems[0]
  const inventory = state.entities.inventory.get(inventoryId)

  const isGuestlist = ['GUESTLIST_TICKET', 'GUESTLIST_TICKET_FREE'].includes(inventory.get('inventoryType'))

  const inventoryItem = state.inventoryCart.cart.find(item => item.inventoryId === inventoryId)
  const maxGuests = inventory.get('entryPerReservation') * inventoryItem.quantity

  return {
    email: state.user.get('email') || state.formFields.get('email'),
    firstName: state.user.get('firstName'),
    lastName: state.user.get('lastName'),
    date: getDateFormatMedium(date),
    eventName: event.get('name'),
    eventTime: getFormattedTime(event.get('eventStartTime')),
    inventoryName: inventory.get('inventoryName'),
    inventoryType: inventory.get('inventoryType'),
    isGuestlist,
    quantity: inventoryItem.quantity,
    maxGuests,
    venueInfo: state.venueInfo,
    minPrice: inventory.get('minPrice'),
    currencyCode: state.venueInfo.currencyCode,
    textMinimumSpend: state.widgetSettings.textMinimumSpend,
  }
}

BookingSuccess = connect(mapStateToProps, null)(BookingSuccess)

export default BookingSuccess
